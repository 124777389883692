import React, { Component } from 'react';
import {
  Icon,
  Button,
  Layout,
  Row,
  Col,
  Table,
  Dropdown,
  Menu,
  Input,
  Modal,
  Skeleton,
  Spin,
  Result,
  Popover,
  Select
} from 'antd';
import ModalView from './components/ModalView';
import ModalForm from './components/ModalForm';
import ModalFormSubAccount from './components/ModalFormSubAccount';
import ModalPriceListEditForm from './components/ModalPriceListForm'
import Avatar from 'react-avatar';
import moment from 'moment'

import './Distributors.scss'


const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const ButtonGroup = Button.Group;

class Distributor extends Component {

  // Inital State for the sorting
  sortState = {
    sortBy: 'created_at',
    orderBy: 'asc'
  };

  // Initial state for searching
  searchState = {};

  pagination = {
    page: 1
  };

  constructor(props) {
    super(props);
    this.state = {
      showFormModal: false,
      showViewModal: false,
      selectedRow: null,
      showFormPricelistModal: false,
      showViewPricelistModal: false,
      showEditPriceListModal: false,
      showSubAccountFormModal: false,
      showAddEditModal: false,
      modalType: '',
      tableData: [],
      filterBy: [
        'customer_code'
      ]
    };
    this.imageUploader = React.createRef();
  }

  handleProductVariantData = (productID, distributorID) => {

    const { onLoadProductVariantData } = this.props

    onLoadProductVariantData({ product_id: productID, distributor_id: distributorID })

  }

  handleSubmit = e => {
    e.preventDefault();

    const { onSubmitAddDistributor, onSubmitUpdateDistributor, form } = this.props
    const { selectedRow, tableData } = this.state;
    const data = {
      page: tableData.current_page
    };

    form.validateFields(async (err, values) => {
      if (!err) {
        let params = {
          ...values
        }

        if (selectedRow) {
          params.id = selectedRow.id;
          await onSubmitUpdateDistributor(params, {...this.sortState, ...this.pagination, ...this.searchState});
        } else {
          await onSubmitAddDistributor(params, {...this.sortState, ...this.pagination, ...this.searchState});
        }

        this.props.form.resetFields();
        this.handleCloseModal()
      }
    });
  };

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
      showAddEditModal: true,
      modalType: 'add',
      selectedRow: null
    });
  };

  handleShowEditFormModal = row => {
    this.setState({
      showFormModal: true,
      selectedRow: row
    });
  };

  handleShowViewModal = row => {
    this.setState({
      showViewModal: true,
      selectedRow: row
    });
  };

  handleShowEditPricelistModal = row => {
    // this.props.onLoadProductVariantData(row.id)
    this.props.onLoadProductsData({ isPaginated: false })
    this.setState({
      showEditPriceListModal: true,
      selectedRow: row
    })
  }

  handleShowSubAccountFormModal = (row) => {
    this.setState({
      showSubAccountFormModal: true,
      selectedRow: row
    })
  }

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      showAddEditModal: false,
      showEditPriceListModal: false,
      showSubAccountFormModal: false,
      selectedRow: null
    });
  };

  handleChangeStatus = (row, status) => {
    const { tableData } = this.state;
    const data = {
      page: tableData.current_page
    };

    const params = {
      id: row.id,
      status: status
    };
    this.props.onSubmitUpdateDistributor(params, {...this.sortState, ...this.pagination, ...this.searchState})
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update Distributor.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Distributor has been updated successfully',
            content: ''
          })
        }
      })
  };

  handleEditDistributor = (row) => {
    this.setState({ showAddEditModal: true, selectedRow: row, modalType: 'edit' })
  }

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadUsersData( { isPaginated: false } )
    this.props.onLoadUserRolesData()
    this.props.onLoadDistributorsData( { filters: JSON.stringify({ status: [1] }) } )
    this.props.onLoadProductsData({ isPaginated: false })
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    this.searchState = searchTerm.trim() === '' ? {} : {
      searchValue: searchTerm,
      searchField: searchKeys
    };

    this.retrieveDistributor({
      page: 1, // reset pagination to 1
    });
  }

  handleFilterBy = (value) => {
    this.setState({ filterBy: value, keyword: "" });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.distributorsData !== nextProps.distributorsData) {
      this.setState({ tableData: nextProps.distributorsData })
    }
  }

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.pagination = {
      page: pagination.current
    };

    this.sortState = {
      sortBy: sorter.field || this.sortState.sortBy,
      orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
      filters: JSON.stringify(filters)
    }

    if (!sorter.order) {
      ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
    }

    this.retrieveDistributor(this.pagination);
  }

  retrieveDistributor(data) {
    this.props.onLoadDistributorsData({...this.sortState, ...data, ...this.searchState});
  }

  render() {
    const { showViewModal,
      selectedRow,
      showEditPriceListModal,
      tableData,
      showAddEditModal,
      showSubAccountFormModal,
      modalType,
      filterBy
    } = this.state
    const {
      myPermissionsLoading, myPermissions,
      usersIsLoading, users,
      rolesIsLoading, roles,
      distributorLoading,
      distributorPricelistLoading,
      distributorProductVariantData,
      productsData,
      productsLoading,
      onSubmitUpdateDistributor,
      onSubmitAddSubAccount
      // distributorsData
    } = this.props
    let dataSource = tableData.data;
    
    if (dataSource === undefined) return false;

    // dataSource.sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix())

    const columns = [
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 150,
        render: (image, row) => {
          if (!image) {
            return <Avatar name={row.name} size='80' round='50%' alt={row.name} />
          }
          return <img src={process.env.REACT_APP_FILES_URL + image} alt={row.name} style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: '50%' }} />
        }
      },
      {
        title: 'Code',
        dataIndex: 'customer_code',
        key: 'customer_code',
        sorter: (a, b) => {
          // const x = a.customer_code.toLowerCase();
          // const y = b.customer_code.toLowerCase();
          // if (x < y) { return -1; }
          // if (x > y) { return 1; }
          // return 0;
        },
      },
      {
        title: 'Distributor Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {
          // const x = a.name.toLowerCase();
          // const y = b.name.toLowerCase();
          // if (x < y) { return -1; }
          // if (x > y) { return 1; }
          // return 0;
        },
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        sorter: (a, b) => {

          // const x = (typeof (a.country) !== undefined ? a.country : '')
          // const y = (typeof (b.country) !== undefined ? b.country : '')

          // if (x < y) { return -1; }
          // if (x > y) { return 1; }
          // return 0;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 200,
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }],
        defaultFilteredValue: [1],
        // onFilter: (value, record) => record.status === value,
        render: (tags, row) => {
          const status = row.status
          // return <span>{status}</span>

          let htmlElement

          if (status === 'Active') htmlElement = <Button className='active'>ACTIVE</Button>
          else if (status === 'Inactive') htmlElement = <Button className='inactive'>INACTIVE</Button>
          return (
            <div>
              <Dropdown
                key={row.id}
                className='status-dropdown'
                overlay={
                  <Menu key={row.id} style={{ top: -40, left: -10 }}>
                    <Menu.Item>
                      <Button
                        className='active'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus(row, 1)
                        }}>ACTIVE</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='inactive'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus(row, 0)
                        }}>INACTIVE</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                // visible={this.state.visible}
                onClick={(e) => {
                  this.setState({ visible: true })
                  e.stopPropagation()
                }} >
                {htmlElement}
              </Dropdown>
            </div>
          )
        }
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 200,
        render: (id, row) => {
          return (
            <div>
              <Popover content={(<p>View Distributor</p>)}>
                <span onClick={() => this.handleShowViewModal(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="eye" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
              <Popover content={(<p>Edit Distributor</p>)}>
                <span onClick={() => this.handleEditDistributor(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="edit" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
              <Popover content={(<p>Edit Pricelist</p>)}>
                <span onClick={() => this.handleShowEditPricelistModal(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="dollar" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
              <Popover content={(<p>Add Sub Account</p>)}>
                <span onClick={() => this.handleShowSubAccountFormModal(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="user-add" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>

            </div>
            //   <Dropdown overlay={(
            //   <Menu>
            //     {(row.status === 'Active') ?
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.distributors.edit')}
            //         onClick={() => this.handleChangeStatus(row, 0)}>Deactivate</Menu.Item> :
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.distributors.edit')}
            //         onClick={() => this.handleChangeStatus(row, 1)}>Activate</Menu.Item>
            //     }
            //     {(row.status === 'Active')
            //       ?
            //       <Menu.Item onClick={() => this.handleShowViewModal(row)}>View Details</Menu.Item>
            //       :
            //       ""
            //     }
            //     {(row.status === 'Active')
            //       ?
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.distributors.edit')}
            //         onClick={() => this.handleEditDistributor(row)}>Edit Distributor</Menu.Item>
            //       :
            //       ""
            //     }
            //     {(row.status === 'Active')
            //       ?
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.distributors.edit')}
            //         onClick={() => this.handleShowEditPricelistModal(row)}>Edit Pricelist</Menu.Item>
            //       :
            //       ""
            //     }
            //     {/* <Menu.Item onClick={() => this.handleShowViewPricelistModal(row)}>View Pricelist</Menu.Item> */}
            //     {(row.status === 'Active')
            //       ?
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.employee.add')}
            //         onClick={() => this.handleShowSubAccountFormModal(row)}>Add Sub Account</Menu.Item>
            //       :
            //       ""
            //     }
            //   </Menu>
            // )}>
            //   <Button>
            //     Actions <Icon type="down" />
            //   </Button>
            // </Dropdown>


          )
        }
      }
    ];

    if (myPermissionsLoading) {
      // return (
      //   <Layout>
      //     <Spin spinning={myPermissionsLoading}>
      //       <Skeleton active />
      //     </Spin>
      //   </Layout>
      // )
      return null
    }

    return myPermissions.some(r => r.name === 'admin.distributors.view') ? (
      <div>
        <Layout>
          <Content style={{ padding: 25 }}>
            <Row>
              <Col span={12} className="page-title">
                Distributors
              </Col>
              <Col span={12} align="right" className="search-and-button">
                <ButtonGroup style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                  <Button
                    className="btn-small btn-primary"
                    disabled={!myPermissions.some(r => r.name === 'admin.distributors.create')}
                    // type="primary"
                    onClick={this.handleShowFormModal}
                    style={{ margin: '10px' }}
                  ><Icon type="user-add" /> Add Distributor</Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <div className="distributors-container">
                <Col>
                  <Search className="search-container" 
                          size="large" 
                          placeholder="Search" 
                          onSearch={(value) => this.handleSearch(value, filterBy)} />
                  <Select style={{ width: 160 }}
                          placeholder="Filter"
                          size="large"
                          defaultValue="customer_code" // changed the defaukt value selected in dropdown to material_code
                          onChange={(value) => this.handleFilterBy(value)}
                          dropdownClassName="--select"
                          autoFocus={true}
                          showSearch>
                            <Option value="customer_code">Code</Option>
                            <Option value="name">Distributor Name</Option>
                            <Option value="country">Country</Option>
                    </Select>
                  <Table
                    rowKey="id"
                    dataSource={dataSource}
                    columns={columns}
                    loading={myPermissionsLoading || distributorLoading}
                    onChange={this.handleOnChangeTableListener} 
                    pagination={{ total: tableData.total, current: tableData.current_page, pageSize: tableData.per_page || 10 }}
                  />
                </Col>
              </div>
            </Row>
          </Content>
        </Layout>
        {showEditPriceListModal &&
          <ModalPriceListEditForm
            showFormModal={showEditPriceListModal}
            handleCloseModal={this.handleCloseModal}
            imageUploader={this.imageUploader}
            selectedRow={selectedRow}
            distributorProductVariantData={distributorProductVariantData}
            productsData={productsData}
            pricelistByProductData={this.handleProductVariantData(productsData[0].currentActiveKey, selectedRow.id)}
            productsLoading={productsLoading}
            isLoading={distributorPricelistLoading}
          />
        }

        <ModalView
          showFormModal={showViewModal}
          handleCloseModal={this.handleCloseModal}
          selectedRow={selectedRow}
        />
        {
          showAddEditModal &&
          <ModalForm
            showAddEditModal={showAddEditModal}
            submitUpdateDistributor={onSubmitUpdateDistributor}
            handleCloseModal={this.handleCloseModal}
            users={users}
            modalType={modalType}
            selectedRow={selectedRow}
            searchParams={{...this.sortState, ...this.pagination, ...this.searchState}}
          />
        }

        <ModalFormSubAccount
          showFormModal={showSubAccountFormModal}
          onSubmitAddSubAccount={onSubmitAddSubAccount}
          handleCloseModal={this.handleCloseModal}
          users={users}
          roles={roles}
          selectedRow={selectedRow}
        />

      </div >
    ) : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

export default Distributor;
