import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadBrandsData,
    submitAddBrand,
    submitUpdateBrand,
    submitDisableBrand
} from './actions';
import {
    fetchPermissions
} from '../../actions'
import Page from './Page';

class Brand extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const BrandForm = Form.create({ name: 'brand_form' })(Brand);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        brandLoading: state.brand.brandLoading,
        disabledBrandLoading: state.brand.disabledBrandLoading,
        updateBrandLoading: state.brand.updateBrandLoading,
        brandsData: state.brand.brandsData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadBrandsData: (data) => dispatch(loadBrandsData(data)),
        onSubmitAddBrand: (data, searchParams) => dispatch(submitAddBrand(data, searchParams)),
        onSubmitUpdateBrand: (data, searchParams) => dispatch(submitUpdateBrand(data, searchParams)),
        onSubmitDisableBrand: (data, searchParams) => dispatch(submitDisableBrand(data, searchParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandForm);
