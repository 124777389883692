import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadOrderData,
    loadOrderStatusData,
    submitAddOrder,
    submitUpdateOrder,
    submitDownloadCsv,
    sendRequest
} from './actions'
import {
    fetchPermissions,
    fetchNotifications,
} from '../../actions'
import Page from './Page';

class Order extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const OrderForm = Form.create({ name: 'order_form' })(Order);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        orderData: state.distributorOrder.orderData,
        orderDataTotalCount: state.distributorOrder.orderDataTotalCount,
        orderStatusData: state.distributorOrder.orderStatusData,
        orderLoading: state.distributorOrder.orderLoading,
        orderCsvData: state.distributorOrder.orderCsvData,
        notifData: state.notifications.notifications,
        requestData: state.requestData.requestData,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        fetchNotifications: (data) => dispatch(fetchNotifications(data)),
        onSendRequest: (data) => dispatch(sendRequest(data)),
        onLoadOrderData: (page) => dispatch(loadOrderData(page)),
        onLoadOrderStatusData: (data) => dispatch(loadOrderStatusData(data)),
        onSubmitAddOrder: (data) => dispatch(submitAddOrder(data)),
        onSubmitUpdateOrder: (data) => dispatch(submitUpdateOrder(data)),
        onSubmitDownloadCSV: (data) => dispatch(submitDownloadCsv(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
