import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
  loadPortfolioBrands,
  submitPortfolioBrands
} from './actions';
import Page from './Page';

class HomePage extends Component {
  render() {
    return (
      <Page {...this.props} />
    );
  }
}

const HomepageForm = Form.create({ name: 'homepage_form' })(HomePage);

function mapStateToProps(state) {
  return {
    portfolioBrandLoading: state.homepage.portfolioBrandLoading,
    portfolioBrandLoaded: state.homepage.portfolioBrandLoaded,
    portfolioBrandRejected: state.homepage.portfolioBrandRejected,
    portfolioBrandData: state.homepage.portfolioBrandData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadPortfolioBrands: (data) => dispatch(loadPortfolioBrands(data)),
    onSubmitPortfolioBrands: (data) => dispatch(submitPortfolioBrands(data))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomepageForm));