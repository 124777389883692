import moment from "moment"

const initialData = {
  feedbackLoading: false,
  feedbackLoaded: false,
  feedbackRejected: false,
  feedbackData: []

}
export default function aboutArticle(state = initialData, action) {
  switch (action.type) {
    case 'GET_FEEDBACK':
      return {
        ...state,
        feedbackLoading: true,
        feedbackLoaded: false,
        feedbackRejected: false
      }
    case 'GET_FEEDBACK_FULFILLED':
      action.payload.map((data, i) => {
        data.po_date = moment(data.po_date).format('MM/DD/YYYY')
        data.batch_rating = `${parseInt(data.batch_rating).toFixed(0).toString()}%`
      })
      return {
        ...state,
        feedbackLoading: false,
        feedbackLoaded: true,
        feedbackData: action.payload
      }
    case 'GET_FEEDBACK_REJECTED':
      return {
        ...state,
        feedbackLoading: false,
        feedbackRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
