import Axios from 'axios'
import { headers } from '../utils/token';

export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const FETCH_PERMISSIONS_FULFILLED = 'FETCH_PERMISSIONS_FULFILLED';
export const FETCH_PERMISSIONS_REJECTED = 'FETCH_PERMISSIONS_REJECTED';

const API_URL = process.env.REACT_APP_API_URL

export const fetchPermissions = (data) => {
    return dispatch => {
        dispatch({
            type: FETCH_PERMISSIONS,
            payload: {}
        })
        return Axios.get(`${API_URL}/my-permissions`, { headers: headers })
            .then(function (response) {
                dispatch({
                    type: FETCH_PERMISSIONS_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                dispatch({
                    type: FETCH_PERMISSIONS_REJECTED,
                    payload: error
                })
            })
    }
}
