let initialData = {
  containerBreakdownData: [],
  containerBreakdownLoading: false,
  containerBreakdownBasedSoNumberData: [],
  containerBreakdownBasedSoNumberLoading: false,
  updateCustomerPoNumberData: [],
  updateCustomerPoNumberLoading: false
};

export default function containerConfiguration(state = initialData, action) {
  switch (action.type) {
    case "GET_CONTAINER_BREAKDOWN":
      return {
        ...state,
        containerBreakdownLoading: true
      };
    case "GET_CONTAINER_BREAKDOWN_FULFILLED":
      action.payload.map(data => {
        data.country === undefined ? data.country = 'N/A' : data.country = data.country
      })
      return {
        ...state,
        containerBreakdownData: action.payload,
        containerBreakdownLoading: false
      };
    case "GET_CONTAINER_BREAKDOWN_REJECTED":
      return {
        ...state,
        containerBreakdownData: "",
        containerBreakdownLoading: false
      };

    case "GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER":
      return {
        ...state,
        containerBreakdownBasedSoNumberLoading: true
      };
    case "GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_FULFILLED":
      return {
        ...state,
        containerBreakdownBasedSoNumberData: action.payload,
        containerBreakdownBasedSoNumberLoading: false
      };
    case "GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_REJECTED":
      return {
        ...state,
        containerBreakdownBasedSoNumberData: "",
        containerBreakdownBasedSoNumberLoading: false
      };

    case "POST_UPDATE_CUSTOMER_PO_NUMBER":
      return {
        ...state,
        updateCustomerPoNumberLoading: true
      };
    case "POST_UPDATE_CUSTOMER_PO_NUMBER_FULFILLED":
      return {
        ...state,
        updateCustomerPoNumberData: action.payload,
        updateCustomerPoNumberLoading: false
      };
    case "POST_UPDATE_CUSTOMER_PO_NUMBER_REJECTED":
      return {
        ...state,
        updateCustomerPoNumberData: "",
        updateCustomerPoNumberLoading: false
      };

    default:
      return {
        ...state
      };
  }
}
