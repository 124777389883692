import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Layout, Spin, Modal } from 'antd';
import './index.scss';

class ResetPassword extends Component {

  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true })
        const urlParams = new URLSearchParams(window.location.search);

        const params = {
          password: values.password,
          password_confirmation: values.password_confirmation,
          token: this.props.match.params.token,
          email: this.props.match.params.email,
        }

        this.props.onSubmitResetPassword(params).then(response => {
          if (response.error) {
            Modal.error({
              title: 'Unable to process the request. Please try again.',
              content: '',
              // icon: <img src={error} alt="error" />
            })
          } else {
            Modal.success({
              title: 'Success! Your password has been changed. You will be redirected to login page. Thank you.',
              content: '',
              // icon: <img src={success} alt="success" />,
              onOk: () => this.props.history.push('/?modalLogin=true')
            })
            this.props.form.resetFields()
          }
          this.setState({ loading: false })
        })
      }
    });
  };

  // componentWillReceiveProps (nextProps) {
  //   if((this.props.loggedIn !== nextProps.loggedIn) && nextProps.loggedIn ){
  //     this.props.history.push('/dashboard')
  //   }
  // }

  componentDidMount() {
    // const { history } = this.props
    // history.push('/forgot-password')
  }

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(<p>The new password and confirmation password do not match.</p>);
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { loading } = this.state
    const { form: { getFieldDecorator }, loggingIn, loginRejected } = this.props;
    return (
      <Layout>
        <div className='login-container'>
          <div className='login-form'>
            {/* <Spin spinning={loggingIn}> */}
            <div className='login-header'>
              <img alt='login-logo' src={'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Universal_Robina_logo_2016.svg/1200px-Universal_Robina_logo_2016.svg.png'} />
              <span>Reset Password</span>
            </div>
            {/* {loginRejected &&
                <div className='login-error-message'>
                  <span>Login Failed: Invalid Credentials</span>
                </div>
              } */}
            <Form onSubmit={this.handleSubmit}>
              {/* <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email for account recovery!' }],
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Email"
                    />,
                  )}
                </Form.Item> */}
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your new password.',
                    },
                    {
                      min: 8,
                      message: 'The password must be at least 8 characters.',
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password_confirmation', {
                  rules: [
                    {
                      required: true,
                      message: 'Please retype your password.',
                    },
                    {
                      min: 8,
                      message: 'The password must be at least 8 characters.',
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(
                  <Input
                    onBlur={this.handleConfirmBlur}
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Re-Type Password"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <Button type="primary" loading={loading} htmlType="submit" className="login-form-button">
                  {loading ? null : "Submit"}
                </Button>
                {/* <br /> */}
                {/* {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(<Checkbox>Remember me</Checkbox>)} */}
              </Form.Item>
            </Form>
            {/* </Spin> */}
          </div>
        </div>
      </Layout>
    );
  }
}

const ResetPasswordForm = Form.create({ name: 'resetPassword_form' })(ResetPassword);

export default ResetPasswordForm;