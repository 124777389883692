import Axios from '../../../utils/axios'

export const loadUsersData = (data) => {
  return dispatch => {
    Axios.get('/admin/users', dispatch, 'GET_USERS_DATA', data)
  }
}

export const submitAddBrand = (data) => {
  return dispatch => {
    return Axios.post(`/admin/brand`, dispatch, 'POST_BRANDS_DATA', data)
      .then((response) => {
        // dispatch(loadBrandsData())
        return response
      })
  }
}

export const submitUpdateUser = (data) => {
  return dispatch => {
    return Axios.post(`/admin/user/${data.id}`, dispatch, 'POST_BRANDS_DATA', data)
      .then((response) => {
        dispatch(loadUsersData())
        return response
      })
  }
}
