import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';
import Papa from 'papaparse'
import { getName } from 'country-list'
import { PDFDocument, StandardFonts } from 'pdf-lib'

const wb = new ExcelJS.Workbook()

function getTotal(data, filterType, column) {
    data.filter((data) => data.type === filterType)
    return data.reduce((a, c) => a + parseFloat(c[column]), 0)
}


function splitArrayIntoChunksOfLen(arr, len) {
    let chunks = [], i = 0, n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}

export const downloadFile = {
    async excel(data, filterType) {

        const isDistributionInquiryShow = filterType === 'Product / Distribution Inquiry'
        const worksheet = wb.addWorksheet("INQUIRY_REPORT")
        // Width of column
        worksheet.getColumn('A').width = 16
        worksheet.getColumn('B').width = 16
        worksheet.getColumn('C').width = 16
        worksheet.getColumn('D').width = 20
        worksheet.getColumn('E').width = 25
        worksheet.getColumn('F').width = 20
        worksheet.getColumn('G').width = 30
        worksheet.getColumn('H').width = 16
        worksheet.getColumn('I').width = 16
        worksheet.getColumn('J').width = 25

        // Header
        isDistributionInquiryShow ? worksheet.mergeCells('A1:J1') : worksheet.mergeCells('A1:G1');
        worksheet.getCell('A1').value = filterType.toUpperCase();
        worksheet.getCell('A1').alignment = { horizontal: 'center' };
        worksheet.getCell('A1').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('A2').value = 'CREATED DATE';
        worksheet.getCell('A2').alignment = { horizontal: 'center' };
        worksheet.getCell('A2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('A2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('B2').value = 'COMPANY NAME';
        worksheet.getCell('B2').alignment = { horizontal: 'center' };
        worksheet.getCell('B2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('B2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('C2').value = 'FULL NAME';
        worksheet.getCell('C2').alignment = { horizontal: 'center' };
        worksheet.getCell('C2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('C2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('D2').value = 'COUNTRY';
        worksheet.getCell('D2').alignment = { horizontal: 'center' };
        worksheet.getCell('D2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('D2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('E2').value = 'EMAIL ADDRESS';
        worksheet.getCell('E2').alignment = { horizontal: 'center' };
        worksheet.getCell('E2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('E2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('F2').value = 'CONTACT NUMBER';
        worksheet.getCell('F2').alignment = { horizontal: 'center' };
        worksheet.getCell('F2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('F2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('G2').value = 'MESSAGE';
        worksheet.getCell('G2').alignment = { horizontal: 'center' };
        worksheet.getCell('G2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('G2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        isDistributionInquiryShow && (worksheet.getCell('H2').value = 'STATUS');
        isDistributionInquiryShow && (worksheet.getCell('H2').alignment = { horizontal: 'center' });
        isDistributionInquiryShow && (worksheet.getCell('H2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } });
        isDistributionInquiryShow && (worksheet.getCell('H2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } });

        isDistributionInquiryShow && (worksheet.getCell('I2').value = 'INQUIRY VOLUME');
        isDistributionInquiryShow && (worksheet.getCell('I2').alignment = { horizontal: 'center' });
        isDistributionInquiryShow && (worksheet.getCell('I2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } });
        isDistributionInquiryShow && (worksheet.getCell('I2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } });

        isDistributionInquiryShow && (worksheet.getCell('J2').value = 'INQUIRY VALUE (IN USD)');
        isDistributionInquiryShow && (worksheet.getCell('J2').alignment = { horizontal: 'center' });
        isDistributionInquiryShow && (worksheet.getCell('J2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } });
        isDistributionInquiryShow && (worksheet.getCell('J2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } });

        const lastRow = 3
        data.map((value, i) => {
            worksheet.getCell((`A${lastRow + i}`).toString()).value = value.created_at || '';
            worksheet.getCell((`B${lastRow + i}`).toString()).value = value.company_name || '';
            worksheet.getCell((`C${lastRow + i}`).toString()).value = value.full_name || '';
            worksheet.getCell((`D${lastRow + i}`).toString()).value = value.country || '';
            worksheet.getCell((`E${lastRow + i}`).toString()).value = value.email_address || '';
            worksheet.getCell((`F${lastRow + i}`).toString()).value = value.contact_number || '';
            worksheet.getCell((`G${lastRow + i}`).toString()).value = value.message || '';
            isDistributionInquiryShow && (worksheet.getCell((`H${lastRow + i}`).toString()).value = value.status !== 'NoStatus' ? value.status : '');
            isDistributionInquiryShow && (worksheet.getCell((`I${lastRow + i}`).toString()).value = value.volume || '');
            isDistributionInquiryShow && (worksheet.getCell((`J${lastRow + i}`).toString()).value = value.cost || 0);
        })


        let totalRow = lastRow + data.length
        isDistributionInquiryShow && (worksheet.getCell(`A${totalRow}`).value = 'TOTAL');
        isDistributionInquiryShow && (worksheet.getCell(`A${totalRow}`).font = { bold: true });
        isDistributionInquiryShow && (worksheet.getCell((`J${totalRow}`).toString()).value = getTotal(data, filterType, "cost").toLocaleString(undefined, { minimumFractionDigits: 2, }) || 0);
        isDistributionInquiryShow && (worksheet.getCell(`J${totalRow}`).alignment = { vertical: 'bottom', horizontal: 'right' });
        isDistributionInquiryShow && (worksheet.getCell(`J${totalRow}`).font = { bold: true });

        const excelData = await wb.xlsx.writeBuffer()

        saveAs(new Blob([excelData]), 'inquiry-report.xlsx')
        wb.removeWorksheet(worksheet.id)
    },


    csv(data, filterType) {
        const newArray = []
        data.map((data) => {
            let filterArray = {}
            if (filterType !== 'Product / Distribution Inquiry') {
                filterArray = {
                    "CREATED DATE": data.created_at,
                    "COMPANY NAME": data.company_name,
                    "FULL NAME": data.full_name,
                    "COUNTRY": data.country,
                    "EMAIL ADDRESS": data.email_address,
                    "CONTACT NUMBER": data.contact_number,
                    "MESSAGE": data.message
                }
            } else {
                filterArray = {
                    "CREATED DATE": data.created_at,
                    "COMPANY NAME": data.company_name,
                    "FULL NAME": data.full_name,
                    "COUNTRY": data.country,
                    "EMAIL ADDRESS": data.email_address,
                    "CONTACT NUMBER": data.contact_number,
                    "MESSAGE": data.message,
                    "STATUS": data.status !== 'NoStatus' ? data.status : '',
                    "INQUIRY VOLUME": data.volume,
                    "INQUIRY VALUE (IN USD)": data.cost
                }
            }
            newArray.push(filterArray)
        })

        const csv = Papa.unparse(newArray)
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "inquiry-report.csv");
    },


    async pdf(data) {
        const pdfDoc = await PDFDocument.create()

        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // Split page by 80
        let pageArray = splitArrayIntoChunksOfLen(data, 40)


        let pageCount = 0
        pageArray.map((value, i) => {
            const page = pdfDoc.addPage([910, 500])
            const { height } = page.getSize()
            page.moveTo(110, 200);

            page.drawText('#', { x: 30, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('DATE', { x: 50, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('FULL NAME', { x: 130, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('COUNTRY', { x: 225, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('EMAIL ADDRESS', { x: 330, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('CONTACT NO.', { x: 480, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('MESSAGE', { x: 570, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('STATUS', { x: 690, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('INQ. VOLUME', { x: 760, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('INQ. VALUE', { x: 840, y: height - 4 * 9, size: 9, font: timesRomanFont })

            value.map((value, i) => {
                pageCount++
                page.drawText((pageCount).toString(), { x: 30, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.created_at}`, { x: 50, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.full_name.length > 18 ? `${value.full_name.substring(0, 18)}...` : value.full_name}`, { x: 130, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.country.length > 18 ? `${value.country.substring(0, 18)}...` : value.country}`, { x: 225, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.email_address.length > 25 ? `${value.email_address.substring(0, 25)}...` : value.email_address}`, { x: 330, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.contact_number}`, { x: 480, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.message.length > 18 ? `${value.message.substring(0, 18)}...` : value.message}`, { x: 570, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.status}`, { x: 690, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.volume || '0'}`, { x: 760, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`$${value.cost.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}`, { x: 840, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })

                // if (Number(data.length) === Number(pageCount)) {
                //     page.drawText(`TOTAL`, { x: 210, y: height - (8 + i) * 9, size: 10, font: timesRomanFont })
                //     page.drawText(`$${this.numberFormat(this.getTotal(data, "total_amount"))}`, { x: 300, y: height - (8 + i) * 9, size: 10, font: timesRomanFont })
                //     page.drawText(`${this.getTotal(data, "ordered_quantity").toLocaleString()}`, { x: 390, y: height - (8 + i) * 9, size: 10, font: timesRomanFont })
                // }
            })
        })

        const pdfBytes = await pdfDoc.save()
        const pdfBlob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        saveAs(pdfBlob, "inquiry-report.pdf");
    }
}


