import React, { Component } from "react";
import moment from "moment";
import ModalView from "./components/ModalView";
// import ResponsesModal from './components/ResponsesModal'
import {
  Upload,
  message,
  Form,
  Icon,
  Button,
  Layout,
  Row,
  Col,
  Table,
  Dropdown,
  Menu,
  Input,
  Skeleton,
  Spin,
  Result,
  Badge,
  Tabs,
  Modal,
  DatePicker,
  Divider,
  Pagination,
  Select
} from "antd";
import BulkDownloadEXcel from './downloadExcel'
import ReadMoreAndLess from 'react-read-more-less';
import ellipsis from "../../assets/svg/ellipsis.svg";
import "./DistributorOrders.scss";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { numberFormat } from "../../utils";

const { Content } = Layout;
const { Search, TextArea } = Input;
const ButtonGroup = Button.Group;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;

const OrderStatusUploadButton = ({ myPermissions }) => {
  const props = {
    name: "file",
    action: `${process.env.REACT_APP_API_URL}/order-status`,
    showUploadList: false,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("SESSION_TOKEN")}`,
      accept: "application/json",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        //
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Upload {...props}>
      <Button
        style={{
          margin: "0 10px",
        }}
        className="btn-small btn-primary"
        disabled={
          !myPermissions.some((r) => r.name === "admin.distributor_orders.create")
        }
      >
        Upload SAP Data
      </Button>
    </Upload>
  );
};

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageLimit: 10,
      pageFilter: '',
      pageSearch: '',
      showResponsesModal: false,
      radio: "all",
      showFormModal: false,
      showViewModal: false,
      showCancelModal: false,
      selectedRow: null,
      selectedRowKeys: [],
      notifData: [],
      tableData: [],
      statusData: [],
      searchValue: "",
      dataSearched: [],
      startDate: null,
      endDate: null,
      remarks: "",
      visible: false,
      visibleCancel: false,
      filterDate: 'All',
      filterTargetETD: 'All',
      filterCustomer: 'All',
      filterCountry: 'All',
      filterOptions: []
    };
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  handleTaggingClick = flag => {
    this.setState({ visible: flag })
  };

  // handleVisibleChange = (flag, row) => {
  //   this.setState({ visible: flag })
  // };

  handleRemarks = e => {
    this.setState({ remarks: e.target.value })
  }

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleShowEditFormModal = (row) => {
    this.setState({
      showFormModal: true,
      selectedRow: row,
    });
  };

  handleShowViewModal = (row) => {
    this.setState({
      showViewModal: true,
      selectedRow: row,
    });
  };

  handleShowResponsesModal = () => {
    this.setState({
      showResponsesModal: true,
    });
  };

  handleShowCancelModal = (row) => {
    this.setState({
      showCancelModal: true,
      selectedRow: row.selectedRow
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      selectedRow: null,
    });
  };

  handleDownloadCSV = (data) => {
    this.props.onSubmitDownloadCSV(data);
  };

  changeStatus = (row, status, remarks) => {
    const params = {
      id: row.id,
      status: status,
      remarks: remarks
    };
    this.props.onSubmitUpdateOrder(params).then((res) => {
      Modal.info({
        title: 'Success',
        content: (
          <div>
            <p>Order Management has been updated.</p>
          </div>
        ),
        onOk() { },
      });
      if (!res.error) {
        this.props.onLoadOrderData({
          currentPage: this.state.currentPage,
          pageLimit: this.state.pageLimit,
          pageFilter: this.state.pageFilter,
          pageSearch: this.state.pageSearch,
          filterOptions: []
        })
      }
    })
    this.setState({ showCancelModal: false })
  };

  getStatus = sapData => {
    sapData = sapData || "";
    let status;
    if (sapData["billing_document"]) status = "invoiced";
    else if (sapData["delivery"]) status = "forLoading";
    else if (sapData["sales_document"]) status = "produced";
    else if (!sapData["sales_document"]) status = "forProduction";
    else status = "";
    return status;
  };

  filterData = (data, predicate) => {
    // if no data is sent in, return null, otherwise transform the data
    return !!!data
      ? null
      : data.reduce((list, entry) => {
        let clone = null;
        if (predicate(entry)) {
          // if the object matches the filter, clone it as it is
          clone = Object.assign({}, entry);
        } else if (entry.order != null) {
          // if the object has orders, filter the list of order
          let order = this.filterData(entry.order, predicate);
          if (order.length > 0) {
            // if any of the order matches, clone the parent object, overwrite
            // the order list with the filtered list
            clone = Object.assign({}, entry, { order: order });
          }
        }
        // if there's a cloned object, push it to the output list
        clone && list.push(clone);
        return list;
      }, []);
  };

  clearFilter() {
    this.setState({
      filterDate: 'All',
      filterTargetETD: 'All',
      filterCustomer: 'All',
      filterCountry: 'All',
      tableData: this.props.orderData,
      filterOptions: []
    })
  }
  componentDidMount() {
    this.props.fetchPermissions()
    this.props.fetchNotifications()
    this.props.onLoadOrderData({
      currentPage: 1,
      pageLimit: this.state.pageLimit,
      pageFilter: this.state.pageFilter,
      pageSearch: this.state.pageSearch,
      filterOptions: []
    })
    //commented this part because 2 api calls are being made on initial load
    // this.props.onLoadOrderStatusData()
  }

  renderCsvData = (products) => {
    let csvRow = [];
    if (products !== undefined) {
      products.forEach((row, index) => {
        const rowData = {
          product: row.product.name,
          material_code: row.variant.material_code,
          product_description: row.variant.name,
          packing_scheme: row.variant.packing_scheme,
          quantity: row.quantity,
          price: row.selling_price,
          cbm: row.variant.cbm,
          totalCbm: row.quantity * row.variant.cbm,
          totalGrossWeight: row.quantity * row.variant.gross_weight,
          partialTotal: row.quantity * row.selling_price,
        };
        csvRow[index] = rowData;
      });
    }

    return csvRow;
  };

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    //function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    };

    const { orderData } = this.props;
    const newArrayObj = orderData;
    //if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ tableData: newArrayObj });
    }
    let distributorArray = [];
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach((keys) => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem });
        let isFound =
          rowItemKeys &&
          rowItemKeys.toLowerCase().includes(searchTerm.toLowerCase());
        if (isFound) distributorArray.push(rowItem);
      });
    });
    //Removed Duplicate Entry in Array.
    const newArrayElement = distributorArray.filter(function (
      elem,
      index,
      self
    ) {
      return index === self.indexOf(elem);
    });
    this.setState({ tableData: newArrayElement });
  };

  handleChangeDate(value) {
    this.state.filterOptions = [];
    var filter = {
      created_at: value !== 'All' ? value : undefined,
      estimated_delivery: this.state.filterTargetETD !== 'All' ? this.state.filterTargetETD : undefined,
      name: this.state.filterCustomer !== 'All' ? this.state.filterCustomer : undefined,
      country: this.state.filterCountry !== 'All' ? this.state.filterCountry : undefined
    };
    Object.keys(filter).forEach(key => filter[key] === undefined ? delete filter[key] : {});
    let data = this.props.orderData && this.props.orderData.filter(function (item) {
      for (var key in filter) {
        if (key === 'created_at') {
          if (item[key] === undefined || item[key] != filter[key]) {
            return false;
          }
        }
        else if (key === 'estimated_delivery') {
          if (item[key] === undefined || moment(item[key]).format('MMMM YYYY') != filter[key]) {
            return false;
          }
        }
        else if (key === 'country') {
          if (item.distributor[key] === undefined || item.distributor[key] != filter[key]) {
            return false;
          }
        } else if (key === 'name') {
          if (item.distributor[key] === undefined || item.distributor[key] != filter[key]) {
            return false;
          }
        }
      }
      return true;
    });
    this.setState({ filterDate: value, tableData: data })
  }

  handleChangeTargetEtd(value) {
    this.state.filterOptions = [];
    var filter = {
      estimated_delivery: value !== 'All' ? value : undefined,
      created_at: this.state.filterDate !== 'All' ? this.state.filterDate : undefined,
      name: this.state.filterCustomer !== 'All' ? this.state.filterCustomer : undefined,
      country: this.state.filterCountry !== 'All' ? this.state.filterCountry : undefined
    };
    Object.keys(filter).forEach(key => filter[key] === undefined ? delete filter[key] : {});
    let data = this.props.orderData && this.props.orderData.filter(function (item) {
      for (var key in filter) {
        if (key === 'created_at') {
          if (item[key] === undefined || item[key] != filter[key]) {
            return false;
          }
        }
        else if (key === 'estimated_delivery') {
          if (item[key] === undefined || moment(item[key]).format('MMMM YYYY') != filter[key]) {
            return false;
          }
        }
        else if (key === 'country') {
          if (item.distributor[key] === undefined || item.distributor[key] != filter[key]) {
            return false;
          }
        } else if (key === 'name') {
          if (item.distributor[key] === undefined || item.distributor[key] != filter[key]) {
            return false;
          }
        }
      }
      return true;
    });
    this.setState({ filterTargetETD: value, tableData: data })
  }

  handleChangeCustomer(value) {
    this.state.filterOptions = [];
    var filter = {
      name: value !== 'All' ? value : undefined,
      created_at: this.state.filterDate !== 'All' ? this.state.filterDate : undefined,
      estimated_delivery: this.state.filterTargetETD !== 'All' ? this.state.filterTargetETD : undefined,
      country: this.state.filterCountry !== 'All' ? this.state.filterCountry : undefined
    };
    Object.keys(filter).forEach(key => filter[key] === undefined ? delete filter[key] : {});
    let data = this.props.orderData && this.props.orderData.filter(function (item) {
      for (var key in filter) {
        if (key === 'created_at') {
          if (item[key] === undefined || item[key] != filter[key]) {
            return false;
          }
        }
        else if (key === 'estimated_delivery') {
          if (item[key] === undefined || moment(item[key]).format('MMMM YYYY') != filter[key]) {
            return false;
          }
        }
        else if (key === 'country') {
          if (item.distributor[key] === undefined || item.distributor[key] != filter[key]) {
            return false;
          }
        } else if (key === 'name') {
          if (item.distributor[key] === undefined || item.distributor[key] != filter[key]) {
            return false;
          }
        }
      }
      return true;
    });
    this.setState({ filterCustomer: value, tableData: data })
  }

  handleChangeCountry(value) {
    this.state.filterOptions = [];
    var filter = {
      country: value !== 'All' ? value : undefined,
      created_at: this.state.filterDate !== 'All' ? this.state.filterDate : undefined,
      estimated_delivery: this.state.filterTargetETD !== 'All' ? this.state.filterTargetETD : undefined,
      name: this.state.filterCustomer !== 'All' ? this.state.filterCustomer : undefined
    };
    Object.keys(filter).forEach(key => filter[key] === undefined ? delete filter[key] : {});
    let data = this.props.orderData && this.props.orderData.filter(function (item) {
      for (var key in filter) {
        if (key === 'created_at') {
          if (item[key] === undefined || item[key] != filter[key]) {
            return false;
          }
        }
        else if (key === 'estimated_delivery') {
          if (item[key] === undefined || moment(item[key]).format('MMMM YYYY') != filter[key]) {
            return false;
          }
        }
        else if (key === 'country') {
          if (item.distributor[key] === undefined || item.distributor[key] != filter[key]) {
            return false;
          }
        } else if (key === 'name') {
          if (item.distributor[key] === undefined || item.distributor[key] != filter[key]) {
            return false;
          }
        }
      }
      return true;
    });
    this.setState({ filterCountry: value, tableData: data })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.orderData !== nextProps.orderData) {
      this.setState({ tableData: nextProps.orderData });
    }

    if (this.props.orderStatusData !== nextProps.orderStatusData) {
      this.setState({ statusData: nextProps.orderStatusData })
      // this.clearFilter();
    }

    if (this.props.notifData !== nextProps.notifData) {
      this.setState({ notifData: nextProps.notifData })
      this.clearFilter();
    }
  }

  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }

  componentDidUpdate(prevState, prevProps) {
    const { startDate, endDate, searchValue } = this.state;
    if (prevState.startDate !== startDate && prevState.endDate !== endDate) {
      prevProps.notifData = this.props.notifData;
    }

    if (searchValue !== prevState.searchValue) {
      if (prevState.startDate !== startDate && prevState.endDate !== endDate) {
        prevProps.notifData = this.props.notifData;
      }
    }
  }

  render() {
    const {
      radio,
      showViewModal,
      showResponsesModal,
      showCancelModal,
      selectedRow,
      tableData,
      statusData,
      selectedRowKeys,
      searchValue,
      dataSearched,
      startDate,
      endDate,
      remarks
    } = this.state;

    const {
      myPermissions,
      myPermissionsLoading,
      notifData,
      orderLoading,
      onSendRequest,
    } = this.props;


    const handleNewSearch = (e) => {
      const result = Object.values(notifData).filter((row) => {
        const search = (arg) => {
          if (arg) {
            return arg
              .toString()
              .toLowerCase()
              .includes(this.state.searchValue.toString().toLowerCase());
          }
        };

        const poReference = () => {
          if (row) {
            if (row.distributor_order !== null) {
              return row.distributor_order.po_reference;
            }
          }
        };

        return search(poReference());
      });

      this.setState({
        searchValue: e.target.value.substr(0, 20),
        dataSearched: result,
      });
    };

    const handleRecentResponsesDatePicker = (value) => {
      const [start, end] = value;
      const startDate = moment(start).unix();
      const endDate = moment(end).unix();
      this.setState({
        startDate,
        endDate,
      });
    };

    const callback = (tab) => {
      // 
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        // Table.SELECTION_ALL,
        // Table.SELECTION_INVERT,
        {
          key: "id",
          text: "Select Odd Row",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        // {
        //   key: 'even',
        //   text: 'Select Even Row',
        //   onSelect: changableRowKeys => {
        //     let newSelectedRowKeys = [];
        //     newSelectedRowKeys = changableRowKeys.filter((key, index) => {
        //       if (index % 2 !== 0) {
        //         return true;
        //       }
        //       return false;
        //     });
        //     this.setState({ selectedRowKeys: newSelectedRowKeys });
        //   },
        // },
      ],
    };

    let dataSource = tableData;

    dataSource && dataSource.map((data, i) => {
      let partialTotalQty = data.product.map((item) => item.quantity);
      let totalQty = partialTotalQty.reduce((a, b) => {
        return a + b;
      }, 0);

      let partialTotalCbm = data.product.map(
        (item) => item.quantity * item.variant.cbm
      );
      let totalCbm = partialTotalCbm.reduce((a, b) => {
        return a + b;
      }, 0);

      let partialTotalGrossWeight = data.product.map(
        (item) => item.quantity * item.variant.gross_weight
      );
      let totalGrossWeight = partialTotalGrossWeight.reduce((a, b) => {
        return a + b;
      }, 0);

      let partialTotalAmount = data.product.map(
        (item) => item.quantity * item.selling_price
      );
      let totalAmount = partialTotalAmount.reduce((a, b) => {
        return a + b;
      }, 0);

      data.totalOrderedQty = totalQty.toString();
      data.totalOrderedAmount = totalAmount.toFixed(2);
      data.totalCbm = totalCbm.toString();
      data.totalGrossWeight = totalGrossWeight.toString();
      // data.sapStatus = []

      // data.product.flatMap(innerData => {
      //   // innerData.sapStatus = []
      //   statusData.flatMap(sap => {
      //     if (sap) {
      //       if (data.po_reference === sap.customer_po_number && innerData.variant.material_code === sap.material) {
      //         data.sapStatus.push({
      //           status: this.getStatus(sap)
      //         })
      //       }
      //       // else if (sap.order_status_notifications) {
      //       //   // data.sapStatus.push({
      //       //   //   status: 'invoiced'
      //       //   // })
      //       //   return 0;
      //       // }
      //     } else {
      //       data.sapStatus.push({
      //         status: 'noSap'
      //       })
      //     }
      //   })
      // })

    });


    // dataSource =
    //   radio === "in-process"
    //     ?
    //     dataSource.filter(value => {
    //       if (value.sapStatus.length > 0) {
    //         return value.sapStatus.some(status => status['status'] === 'forProduction') ||
    //           value.sapStatus.some(status => status['status'] === 'forLoading') ||
    //           value.sapStatus.some(status => status['status'] === 'produced')
    //       } else if (value.sapStatus.length === 0) {
    //         return true
    //       }
    //     })
    //     : radio === "invoiced"
    //       ? dataSource.filter(value => {
    //         if (value.sapStatus.length > 0) {
    //           return value.sapStatus.every(status => status['status'] === 'invoiced')
    //         }
    //       }) : "no data"

    // if (Array.isArray(dataSource)) {
    //   dataSource =
    //     radio === 'all'
    //       ? dataSource :
    //       radio === "in process"
    //         ? dataSource.filter(value => value.status === 'In Process')
    //         : radio === "invoiced"
    //           ? dataSource.filter(value => value.status === 'Invoiced')
    //           : radio === 'cancelled'
    //             ? dataSource.filter(value => value.status === 'Cancelled')
    //             : 'no data'
    // } else {
    //   dataSource = []
    // }

    const columns = [
      {
        title: "PO DATE",
        dataIndex: "created_at",
        key: "created_at",
        width: 120,
        sorter: (a, b) => {
          const x = a.created_at.toLowerCase();
          const y = b.created_at.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        render: (created_at) => {
          return <span style={{ whiteSpace: "nowrap" }}>{created_at}</span>;
        },
      },
      {
        title: "DISTRIBUTOR",
        dataIndex: "distributor",
        key: "distributor",
        sorter: (a, b) => {
          const x = a.distributor.name.toLowerCase();
          const y = b.distributor.name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        render: (distributor) => {
          return <span>{distributor && distributor.name}</span>;
        },
      },
      {
        title: "CUSTOMER PO NUMBER",
        dataIndex: "sales_ref_number",
        key: "sales_ref_number",
        sorter: (a, b) => {
          const x = a.sales_ref_number.toLowerCase();
          const y = b.sales_ref_number.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        render: (sales_ref_number, row) => {
          return (
            <a href="#" onClick={() => this.handleShowViewModal(row)}>
              {sales_ref_number}
            </a>
          );
        },
      },
      {
        title: "PO NUMBER",
        dataIndex: "po_reference",
        key: "po_reference",
        sorter: (a, b) => {
          const x = a.po_reference.toLowerCase();
          const y = b.po_reference.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        render: (po_reference, row) => {
          return (
            <a href="#" onClick={() => this.handleShowViewModal(row)}>
              {po_reference}
            </a>
          );
        },
      },
      {
        title: "ORDER NUMBER",
        dataIndex: "transaction_number",
        key: "transaction_number",
        sorter: (a, b) => {
          const x = a.transaction_number.toLowerCase();
          const y = b.transaction_number.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        render: (transaction_number, row) => {
          return (
            <a href="#" onClick={() => this.handleShowViewModal(row)}>
              {transaction_number}
            </a>
          );
        },
      },
      {
        title: 'ORDER STATUS',
        dataIndex: 'status',
        key: 'status',
        // width: 40,
        filters: [{ text: "In Process", value: 'In Process' }, { text: "Invoiced", value: 'Invoiced' }, { text: "Cancelled", value: 'Cancelled' }],
        onFilter: (value, record) => record.status === value,
        render: (id, data) => {

          let htmlElement
          if (data.status === 'In Process') htmlElement = <Button className='order-tag-inprocess'>IN PROCESS</Button>
          else if (data.status === 'Invoiced') htmlElement = <Button className='order-tag-invoiced'>INVOICED</Button>
          else if (data.status === 'Cancelled') htmlElement = <Button className='order-tag-cancelled'>CANCELLED</Button>
          return (
            <div style={{ width: 100 }}>
              <Dropdown
                key={data.id}
                id={data.id}
                overlay={
                  <Menu title={data.id} key={data.id} style={{ top: -30, left: -15 }}>
                    <Menu.Item>
                      <Button
                        className='order-tag-inprocess'
                        style={{ width: "100%" }}
                        onClick={(e) => {
                          e.stopPropagation()
                          this.changeStatus(data, 1, null)
                          // this.handleTaggingClick()
                        }}>IN PROCESS</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='order-tag-invoiced'
                        style={{ width: "100%" }}
                        onClick={(e) => {
                          e.stopPropagation()
                          this.changeStatus(data, 2, null)
                          // this.handleTaggingClick()
                        }}>INVOICED</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='order-tag-cancelled'
                        style={{ width: "100%" }}
                        onClick={(e) => {
                          e.stopPropagation()
                          // this.setState({ visibleCancel: false })
                          this.handleShowCancelModal({ showCancelModal: true, selectedRow: data })

                          // this.handleTaggingClick()
                        }}>CANCEL</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['hover']}
                // onVisibleChange={this.handleTaggingClick}
                // visible={data.id === }
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                {htmlElement}
              </Dropdown>
            </div>

          )
        }
      },
      {
        title: (
          <span>
            ORDERED <br />
            QTY (IN CS)
          </span>
        ),
        dataIndex: "totalOrderedQty",
        key: "totalOrderedQty",
        align: "right",
        sorter: (a, b) => {
          const x = a.totalOrderedQty.toLowerCase();
          const y = b.totalOrderedQty.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        render: (totalOrderedQty) => {
          return <span>{parseFloat(totalOrderedQty).toLocaleString()}</span>;
        },
      },
      {
        title: (
          <span>
            AMOUNT (IN USD)
          </span>
        ),
        dataIndex: "totalOrderedAmount",
        key: "totalOrderedAmount",
        align: "right",
        render: (e, i) => (
          <div>${numberFormat(i.totalOrderedAmount)}</div>
        ),
      },
      {
        title: "CBM",
        dataIndex: "totalCbm",
        key: "totalCbm",
        align: "right",
        render: (e, i) => <div>{Number(i.totalCbm).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</div>,
      },
      {
        title: "GROSS WEIGHT",
        dataIndex: "totalGrossWeight",
        key: "totalGrossWeight",
        align: "right",
        render: (e, i) => <div>{parseFloat(i.totalGrossWeight).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</div>,
      },
      {
        title: "CONTAINER OPTIONS",
        dataIndex: "volume",
        key: "volume",
        render: (e, i) => <div>{e}</div>,
        sorter: (a, b) => {
          const x = a.volume.toLowerCase();
          const y = b.volume.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: (<span>TARGET<br />ETD</span>),
        dataIndex: "estimated_delivery",
        key: "estimated_delivery",
        sorter: (a, b) =>
          moment(a.targetedEtd).unix() - moment(b.targetedEtd).unix(),
        render: (estimated_delivery) => {
          return <span style={{ whiteSpace: "nowrap" }}>{moment(estimated_delivery).format('MMMM YYYY')}</span>;
        },
      },
      {
        title: "REMARKS",
        dataIndex: "remarks",
        key: "remarks",
        align: "center",
        sorter: (a, b) => {
          const x = a.remarks.toLowerCase();
          const y = b.remarks.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        render: (remarks) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <div style={{ width: 80, overflowWrap: 'break-word' }}>
                {remarks ? (<ReadMoreAndLess
                  // ref={data.message}
                  className="read-more-content"
                  charLimit={8}
                  readMoreText="Read more"
                  readLessText="...Read less"
                >
                  {remarks}
                </ReadMoreAndLess>) :
                  remarks
                }
              </div>
            </div>
          );
        },
      },
    ];

    if (myPermissionsLoading) {
      // return (
      //   <Layout>
      //     <Skeleton active />
      //   </Layout>
      // );
      return null
    }

    return myPermissions.some((r) => r.name === "admin.distributor_orders.view") ? (
      <div>
        <Layout className="orders-container">
          <Content style={{ padding: 25 }}>
            <Content className="orders-header">
              <Row type="flex" justify="space-between">
                <Col className="page-title" style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
                  <h1>Order Management</h1>
                </Col>
                <Col style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                  {/* <Button
                  className="btn-small btn-primary"
                  onClick={() => this.setState({ showResponsesModal: true })}
                  style={{ marginRight: 20 }}
                >
                  View Recent Responses
                </Button> */}
                  {/* <OrderStatusUploadButton
                  myPermissions={myPermissions}
                /> */}
                  <BulkDownloadEXcel
                    className="btn-small btn-primary"
                    title="Download Multiple PO"
                    data={tableData}
                    rowIds={selectedRowKeys}
                  // style={{ background: '#A80F1B', color: 'white' }}
                  />
                </Col>
                {/* <Col span={6} align="right">
                <ButtonGroup style={{ display: 'flex', marginTop: -10 }}>
                  <Search placeholder="Search" onSearch={(value) => this.handleSearch(value, [
                    'transaction_number',
                    'po_reference',
                    'sales_ref_number',
                    'status',
                    'created_at',
                    'special_instructions',
                    'port_destination',
                    'distributor.name',
                    'distributor.customer_code',
                    'distributor.country'
                  ])} enterButton style={{ margin: '10px' }} />
                </ButtonGroup>
              </Col> */}
                {/* <Col span={4} align="right">
                <BulkDownloadEXcel
                  title="Download Multiple PO"
                  data={tableData}
                  rowIds={selectedRowKeys}
                  style={{ background: '#A80F1B', color: 'white' }}
                />
              </Col> */}

              </Row>
            </Content>
            {/* Commented out filter enhancements to order management for now */}
            {/* <Content className='orders-filter'>
            <Row type="flex" justify="start" gutter={32}>
              <Col className='col-filter'>
                <label>DATE</label>
                <Select value={this.state.filterDate} onChange={(value) => this.handleChangeDate(value)} style={{ width: 150 }} showSearch>
                  <Option value='All'  key={-1}>All</Option>
                  {
                     this.props.orderData && 
                     this.props.orderData.map((item, i) => {
                      if((this.state.filterTargetETD === 'All' && this.state.filterCustomer === 'All' && this.state.filterCountry === 'All') ||
                        (this.state.filterTargetETD === 'All' && this.state.filterCustomer === 'All' && this.state.filterCountry === item.distributor.country)||
                        (this.state.filterTargetETD === 'All' && this.state.filterCustomer === item.distributor.name && this.state.filterCountry === 'All')||
                        (this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY') && this.state.filterCustomer === 'All' && this.state.filterCountry === 'All') ||
                        (this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY') && this.state.filterCustomer === item.distributor.name && this.state.filterCountry === 'All') ||
                        (this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY') && this.state.filterCustomer === 'All' && this.state.filterCountry === item.distributor.country) ||
                        (this.state.filterTargetETD === 'All' && this.state.filterCustomer === item.distributor.name && this.state.filterCountry === item.distributor.country) ||
                        (this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY') && this.state.filterCustomer === item.distributor.name && this.state.filterCountry === item.distributor.country) 
                        )
                      {
                          //filter options array ensures that there won't be any duplicates in filters
                          if(!(this.state.filterOptions.includes(item.created_at))){
                          this.state.filterOptions.push(item.created_at);
                          return (
                            <Option key={i} value={item.created_at}>{item.created_at}</Option>
                          )
                        }
                      }
                     })
                  }
                  </Select>
              </Col>
              <Col className='col-filter'>
                <label>TARGET ETD</label>
                <Select value={this.state.filterTargetETD} onChange={(value) => this.handleChangeTargetEtd(value)} style={{ width: 150 }} showSearch>
                  <Option value='All' key={-1}>All</Option>
                  {
                     this.props.orderData && 
                     this.props.orderData.map((item, i) => {
                      if((this.state.filterDate === 'All' && this.state.filterCustomer === 'All' && this.state.filterCountry === 'All') ||
                      (this.state.filterDate === 'All' && this.state.filterCustomer === 'All' && this.state.filterCountry === item.distributor.country)||
                      (this.state.filterDate === 'All' && this.state.filterCustomer === item.distributor.name && this.state.filterCountry === 'All')||
                      (this.state.filterDate === item.created_at && this.state.filterCustomer === 'All' && this.state.filterCountry === 'All') ||
                      (this.state.filterDate === item.created_at && this.state.filterCustomer === item.distributor.name && this.state.filterCountry === 'All') ||
                      (this.state.filterDate === item.created_at && this.state.filterCustomer === 'All' && this.state.filterCountry === item.distributor.country) ||
                      (this.state.filterDate === 'All' && this.state.filterCustomer === item.distributor.name && this.state.filterCountry === item.distributor.country) ||
                      (this.state.filterDate === item.created_at && this.state.filterCustomer === item.distributor.name && this.state.filterCountry === item.distributor.country) 
                      )
                      {
                        //filter options array ensures that there won't be any duplicates in filters
                        if(!(this.state.filterOptions.includes(moment(item.estimated_delivery).format('MMMM YYYY')))){
                          this.state.filterOptions.push(moment(item.estimated_delivery).format('MMMM YYYY'));
                          return (
                            <Option key={i} value={moment(item.estimated_delivery).format('MMMM YYYY')}>{moment(item.estimated_delivery).format('MMMM YYYY')}</Option>
                          )
                        }
                      }
                     })
                  }
                  </Select>
              </Col>
              <Col className='col-filter'>
                <label>CUSTOMER</label>
                <Select value={this.state.filterCustomer} onChange={(value) => this.handleChangeCustomer(value)} style={{ width: 150 }} showSearch>
                  <Option value='All' key={-1}>All</Option>
                  {
                     this.props.orderData && 
                     this.props.orderData.map((item, i) => {
                      if((this.state.filterDate === 'All' && this.state.filterTargetETD === 'All' && this.state.filterCountry === 'All') ||
                      (this.state.filterDate === 'All' && this.state.filterTargetETD === 'All' && this.state.filterCountry === item.distributor.country)||
                      (this.state.filterDate === 'All' && this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY') && this.state.filterCountry === 'All')||
                      (this.state.filterDate === item.created_at && this.state.filterTargetETD === 'All' && this.state.filterCountry === 'All') ||
                      (this.state.filterDate === item.created_at && this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY') && this.state.filterCountry === 'All') ||
                      (this.state.filterDate === item.created_at && this.state.filterTargetETD === 'All' && this.state.filterCountry === item.distributor.country) ||
                      (this.state.filterDate === 'All' && this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY') && this.state.filterCountry === item.distributor.country) ||
                      (this.state.filterDate === item.created_at && this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY') && this.state.filterCountry === item.distributor.country) 
                      )
                      {
                        //filter options array ensures that there won't be any duplicates in filters
                        if(!(this.state.filterOptions.includes(item.distributor.name))){
                          this.state.filterOptions.push(item.distributor.name);
                          return (
                            <Option key={i} value={item.distributor.name}>{item.distributor.name}</Option>
                          )
                        }
                      }
                     })
                  }
                  </Select>
              </Col>
              <Col className='col-filter'>
                <label>COUNTRY</label>
                <Select value={this.state.filterCountry} onChange={(value) => this.handleChangeCountry(value)} style={{ width: 150 }} showSearch>
                  <Option value='All' key={-1}>All</Option>
                  {
                     this.props.orderData && 
                     this.props.orderData.map((item, i) => {
                      if((this.state.filterDate === 'All' && this.state.filterCustomer === 'All' && this.state.filterTargetETD === 'All') ||
                        (this.state.filterDate === 'All' && this.state.filterCustomer === 'All' && this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY'))||
                        (this.state.filterDate === 'All' && this.state.filterCustomer === item.distributor.name && this.state.filterTargetETD === 'All')||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === 'All' && this.state.filterTargetETD === 'All') ||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === item.distributor.name && this.state.filterTargetETD === 'All') ||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === 'All' && this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY')) ||
                        (this.state.filterDate === 'All' && this.state.filterCustomer === item.distributor.name && this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY')) ||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === item.distributor.name && this.state.filterTargetETD === moment(item.estimated_delivery).format('MMMM YYYY')) 
                        )
                      {  //filter options array ensures that there won't be any duplicates in filters
                        if(!(this.state.filterOptions.includes(item.distributor.country))){
                          this.state.filterOptions.push(item.distributor.country);
                          return (
                            <Option key={i} value={item.distributor.country}>{item.distributor.country}</Option>
                          )
                        }
                      }
                     })
                  }
                  </Select>
              </Col>
              <Col >
                <Content className='col-filter-reset'>
                  <label onClick={() => this.clearFilter()}>Reset Filter</label>
                </Content>
              </Col>
              </Row>
            </Content> */}
            {/* <div className="orders-table"> */}
            <Content className='orders-content'>
              <Row>
                {/* <Col span={6} align="right" className="search-container"> */}
                {/* <ButtonGroup style={{ display: "flex", marginTop: 10 }}> */}
                <Content className='content-filter'>
                  <div>
                    <Search
                      // size="large"
                      className="search-container"
                      placeholder="Search"
                      onSearch={(value) =>
                      // this.handleSearch(value, [
                      //   "transaction_number",
                      //   "po_reference",
                      //   "sales_ref_number",
                      //   "status",
                      //   "remarks",
                      //   "created_at",
                      //   "created_at_whole",
                      //   "special_instructions",
                      //   "port_destination",
                      //   "volume",
                      //   "remarks",
                      //   "distributor.name",
                      //   "distributor.customer_code",
                      //   "distributor.country",
                      //   "totalOrderedQty",
                      //   "totalOrderedAmount",
                      //   "totalCbm",
                      //   "totalGrossWeight"
                      // ])
                      {
                        this.props.onLoadOrderData({
                          currentPage: 1,
                          pageLimit: this.state.pageLimit,
                          pageFilter: this.state.pageFilter,
                          pageSearch: value,
                          filterOptions: []
                        })
                        this.setState({ currentPage: 1, filterOptions: [], pageSearch: value })
                      }

                      }
                      style={{ width: 230 }}
                    />
                  </div>
                  <div className="status-radio-filter">
                    <h1>STATUS</h1>
                    <label className="radio">
                      ALL
                  <input
                        type="radio"
                        name="status"
                        checked={radio === "all"}
                        value="all"
                        onChange={(e) => {
                          this.props.onLoadOrderData({
                            currentPage: 1,
                            pageLimit: this.state.pageLimit,
                            pageFilter: '',
                            pageSearch: this.state.pageSearch,
                            filterOptions: []
                          })
                          this.setState({ radio: e.target.value, currentPage: 1, pageFilter: '', filterOptions: [] })
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      IN PROCESS
                  <input
                        type="radio"
                        name="status"
                        checked={radio === "in process"}
                        value="in process"
                        onChange={(e) => {
                          this.props.onLoadOrderData({
                            currentPage: 1,
                            pageLimit: this.state.pageLimit,
                            pageFilter: 1,
                            pageSearch: this.state.pageSearch,
                            filterOptions: []
                          })
                          this.setState({ radio: e.target.value, currentPage: 1, pageFilter: 1, filterOptions: [] })
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      INVOICED
                  <input
                        type="radio"
                        name="status"
                        checked={radio === "invoiced"}
                        value="invoiced"
                        onChange={(e) => {
                          this.props.onLoadOrderData({
                            currentPage: 1,
                            pageLimit: this.state.pageLimit,
                            pageFilter: 2,
                            pageSearch: this.state.pageSearch,
                            filterOptions: []
                          })
                          this.setState({ radio: e.target.value, currentPage: 1, pageFilter: 2, filterOptions: [] })
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      CANCELLED
                  <input
                        type="radio"
                        name="status"
                        checked={radio === "cancelled"}
                        value="cancelled"
                        onChange={(e) => {
                          this.props.onLoadOrderData({
                            currentPage: 1,
                            pageLimit: this.state.pageLimit,
                            pageFilter: e.target.value,
                            pageSearch: this.state.pageSearch,
                            filterOptions: []
                          })
                          this.setState({ radio: e.target.value, currentPage: 1, pageFilter: e.target.value, filterOptions: [] })
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </Content>
                {/* </ButtonGroup> */}
                {/* </Col> */}
              </Row>
            </Content>

            <Row>
              <Col>
                <Table
                  className="order-table-component"
                  rowKey="id"
                  pagination={false}
                  dataSource={dataSource}
                  rowSelection={rowSelection}
                  columns={columns}
                  loading={myPermissionsLoading || orderLoading}
                  onChange={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0;
                  }}
                  // onRow={(row, rowIndex) => {
                  //   return <span style={{ width: "100%" }}>{row}</span>
                  // }}
                  // style={{
                  //   padding: 25,
                  // }}
                  onRowClick={(r, index) => {
                    this.handleShowViewModal(r)
                  }}
                />
              </Col>
              <Col align='right' >
                <div style={{ margin: 20 }}>
                  <Pagination current={this.state.currentPage} onChange={(page) => {
                    this.props.onLoadOrderData({
                      currentPage: page,
                      pageLimit: this.state.pageLimit,
                      pageFilter: this.state.pageFilter,
                      pageSearch: this.state.pageSearch,
                      pageSort: { sortBy: 'name', orderBy: 'asc'},
                      filterOptions: []
                    }
                    )
                    this.setState({
                      currentPage: page, pageLimit: this.state.pageLimit,
                      pageFilter: this.state.pageFilter,
                      pageSearch: this.state.pageSearch, filterOptions: [],
                      pageSort: { sortBy: 'name', orderBy: 'asc'}
                    })
                  }} total={this.props.orderDataTotalCount} />
                </div>
              </Col>
            </Row>
            {/* </div> */}
          </Content>
        </Layout>

        <ModalView
          {...this.props}
          showFormModal={showViewModal}
          handleCloseModal={this.handleCloseModal}
          selectedRow={selectedRow}
          onSendRequest={onSendRequest}
        />

        {/* For Recent Responses Template */}
        <Modal
          title="View Recent Responses"
          visible={showResponsesModal}
          onCancel={() => this.setState({ showResponsesModal: false })}
          width={"50%"}
          footer={null}
          className="responses-modal"
        >
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="All" key="1">
              <span className="recent-responses">Recent Responses</span>
              <Row type="flex">
                <Col>
                  <Search
                    size="large"
                    placeholder="Search"
                    value={this.state.searchValue}
                    onChange={handleNewSearch}
                    style={{ margin: "10px", width: 150 }}
                  />
                </Col>
                <Col>
                  <span className="datepicker-label">DATE </span>{" "}
                  <RangePicker
                    className="datepicker"
                    size="large"
                    style={{ width: 200 }}
                    onChange={handleRecentResponsesDatePicker}
                  />
                </Col>
              </Row>
              {/* <h1>Date</h1> */}

              {(searchValue.length >= 1 ? dataSearched : notifData)
                .filter((response) => {
                  return (
                    response.notif_type === "sku replacement" &&
                    response.response !== null
                  );
                })
                .filter((row) => {
                  if (startDate !== null && endDate !== null) {
                    return (
                      moment(row.created_at).unix() > startDate &&
                      moment(row.created_at).unix() < endDate
                    );
                  } else {
                    return row;
                  }
                })
                .map((response, index) => {
                  return (
                    <div key={index}>
                      <Divider />
                      <p>
                        Your request from SO #:{" "}
                        <strong>
                          {response.distributor_order && response.distributor_order.transaction_number}
                        </strong>{" "}
                        was{" "}
                        {response.response === "yes" ? (
                          <span className="approved">
                            <strong>approved</strong>
                          </span>
                        ) : (
                            <span className="not-approved">
                              <strong>not approved</strong>
                            </span>
                          )}{" "}
                        by {response.distributor.name} to change product/s.{" "}
                        <br />
                        Comment:{" "}<strong><em>{response.description}</em></strong><br />
                        <span className="single-notif-date">
                          {moment(response.updated_at).format("LLL")}
                        </span>
                      </p>
                    </div>
                  );
                })}
            </TabPane>
            <TabPane tab="Approved" key="2">
              <span className="recent-responses">Recent Responses</span>
              <Row type="flex">
                <Col>
                  <Search
                    size="large"
                    placeholder="Search"
                    value={this.state.searchValue}
                    onChange={handleNewSearch}
                    style={{ margin: "10px", width: 150 }}
                  />
                </Col>
                <Col>
                  <span className="datepicker-label">DATE </span>{" "}
                  <RangePicker
                    className="datepicker"
                    size="large"
                    style={{ width: 200 }}
                  />
                </Col>
              </Row>
              {/* <h1>Date</h1> */}
              {(searchValue.length >= 1 ? dataSearched : notifData)
                .filter((response) => {
                  return (
                    response.notif_type === "sku replacement" &&
                    response.response === "yes"
                  );
                })
                .filter((row) => {
                  if (startDate !== null && endDate !== null) {
                    return (
                      moment(row.created_at).unix() > startDate &&
                      moment(row.created_at).unix() < endDate
                    );
                  } else {
                    return row;
                  }
                })
                .map((response, index) => {
                  return (
                    <div key={index}>
                      <Divider />
                      <p>
                        Your request from PO Number{" "}
                        <strong>
                          {response.distributor_order && response.distributor_order.transaction_number}
                        </strong>{" "}
                        was{" "}
                        <span className="approved">
                          <strong>approved</strong>
                        </span>{" "}
                        by {response.distributor.name} to change product/s.{" "}
                        <br />
                        Comment:{" "}<strong><em>{response.description}</em></strong><br />
                        <span className="single-notif-date">
                          {moment(response.updated_at).format("LLL")}
                        </span>
                      </p>
                    </div>
                  );
                })}
            </TabPane>
            <TabPane tab="Not Approved" key="3">
              <span className="recent-responses">Recent Responses</span>
              <Row type="flex">
                <Col>
                  <Search
                    size="large"
                    placeholder="Search"
                    value={this.state.searchValue}
                    onChange={handleNewSearch}
                    style={{ margin: "10px", width: 150 }}
                  />
                </Col>
                <Col>
                  <span className="datepicker-label">DATE </span>{" "}
                  <RangePicker
                    className="datepicker"
                    size="large"
                    style={{ width: 200 }}
                  />
                </Col>
              </Row>
              {/* <h1>Date</h1> */}
              {(searchValue.length >= 1 ? dataSearched : notifData)
                .filter((response) => {
                  return (
                    response.notif_type === "sku replacement" &&
                    response.response === "no"
                  );
                })
                .filter((row) => {
                  if (startDate !== null && endDate !== null) {
                    return (
                      moment(row.created_at).unix() > startDate &&
                      moment(row.created_at).unix() < endDate
                    );
                  } else {
                    return row;
                  }
                })
                .map((response, index) => {
                  return (
                    <div key={index}>
                      <Divider />
                      <p>
                        Your request from PO Number{" "}
                        <strong>
                          {response.distributor_order && response.distributor_order.transaction_number}
                        </strong>{" "}
                        was{" "}
                        <span className="not-approved">
                          <strong>not approved</strong>
                        </span>{" "}
                        by {response.distributor.name} to change product/s.{" "}
                        <br />
                        Comment:{" "}<strong><em>{response.description}</em></strong><br />
                        <span className="single-notif-date">
                          {moment(response.updated_at).format("LLL")}
                        </span>
                      </p>
                    </div>
                  );
                })}
            </TabPane>
          </Tabs>
        </Modal>
        <Modal
          title="Cancel Order"
          visible={showCancelModal}
          onCancel={() => this.setState({ showCancelModal: false })}
          width={"45%"}
          footer={null}
          className="order-status-change-modal"
        >
          <p><Icon type="warning" style={{ color: "red", fontSize: "1.6rem" }} /> Do you really want to cancel this order?</p>
          <TextArea className="remarks-input" name="remarks" value={remarks} onChange={this.handleRemarks} rows={2} placeholder="Reason for cancelling.." />
          <Row type="flex" style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <Col><Button className="btn-small btn-default" onClick={async () => {
              await this.setState({ showCancelModal: false })
              await this.setState({ remarks: "" })
            }} style={{ marginBottom: 30 }}>No</Button></Col>
            <Col>
              <Button
                className="btn-small btn-primary"
                onClick={async () => {
                  await this.changeStatus(selectedRow, 0, remarks)
                  await this.setState({ remarks: "" })
                }}
                style={{ marginBottom: 30 }}
              >
                Yes
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    ) : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

const OrderForm = Form.create({ name: "order_form" })(Order);

export default OrderForm;
