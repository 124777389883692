import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
  loadAboutArticle,
  submitUpdateArticle,
  submitAddArticle
} from './actions';
import Page from './Page';

class Login extends Component {
  render() {
    return (
      <Page {...this.props} />
    );
  }
}

const LoginForm = Form.create({ name: 'login_form' })(Login);

function mapStateToProps(state) {
  const { articleData, articleLoading } = state.articleAbout
  return {
    tableData: articleData,
    articleLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadAboutArticle: (data) => dispatch(loadAboutArticle(data)),
    onSubmitAddArticle: (data) => dispatch(submitAddArticle(data)),
    onSubmitUpdateArticle: (data) => dispatch(submitUpdateArticle(data))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));