const initialData = {
    notificationsLoading: false,
    notifications: [],
    requestDataLoading: false,
    requestData: []
}

export default function permissions(state = initialData, action) {
    switch (action.type) {
        case 'FETCH_NOTIFICATION':
            return {
                ...state,
                notificationsLoading: true
            }
        case 'FETCH_NOTIFICATION_FULFILLED':
            return {
                ...state,
                notifications: action.payload,
                notificationsLoading: false
            }
        case 'FETCH_NOTIFICATION_REJECTED':
            return {
                ...state,
                notifications: [],
                notificationsLoading: false
            }

        case 'SEND_REQUEST':
            return {
                ...state,
                requestDataLoading: true
            }
        case 'SEND_REQUEST_FULFILLED':
            return {
                ...state,
                requestData: action.payload,
                requestDataLoading: false
            }
        case 'SEND_REQUEST_REJECTED':
            return {
                ...state,
                requestData: [],
                requestDataLoading: false
            }

        default:
            return {
                ...state
            }
    }
}
