import Axios from '../../../utils/axios'
import axios from 'axios'
import { headers } from '../../../utils/token'
import moment from 'moment'

export const loadOrderData = (page) => {
  const {
    currentPage,
    pageLimit,
    pageFilter,
    pageSearch
  } = page
  return dispatch => {
    Axios.get(`/admin/orders-reconfig?limit=${pageLimit}&page=${currentPage}&filter=${pageFilter}&search=${pageSearch}`, dispatch, 'GET_ORDER_DATA')
  }
}

export const loadOrderStatusData = (data) => {
  return dispatch => {
    Axios.get('/admin/orders-reconfig', dispatch, 'GET_ORDER_STATUS_DATA', data)
  }
}

export const fetchNotifications = (data) => {
  return dispatch => {
    Axios.get('/admin/notifications', dispatch, 'DISTRIBUTOR_NOTIFICATION', data)
  }
}

export const sendRequest = (data) => {
  return dispatch => {
    return Axios.post(`/admin/notifications`, dispatch, 'SEND_NOTIFICATION', data)
      .then((response) => {
        dispatch(fetchNotifications())
        return response
      })
  }
}

export const submitAddOrder = (data) => {
  return dispatch => {
    Axios.post(`/admin/order`, dispatch, 'POST_ORDER_DATA', data)
      .then(() => {
        dispatch(loadOrderData())
      })
  }
}

export const submitDownloadCsv = (data) => {
  return dispatch => {
    return axios({
      url: `${process.env.REACT_APP_API_URL}/order-csv/${data.id}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: headers
    })
      .then((response) => {
        dispatch({
          type: `GET_ORDER_CSV_DATA_FULFILLED`,
          payload: response.data
        })

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let filename = `${moment(data.created_at).format("MM-DD-YYYY")}_${data.transaction_number}.xlsx`
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        dispatch({
          type: `GET_ORDER_CSV_DATA_REJECTED`,
          payload: error
        })
      });
  }
}

export const submitUpdateOrder = (data) => {
  return dispatch => {
    return Axios.post(`/admin/change-order-status/${data.id}`, dispatch, 'POST_ORDER_DATA', data)
      .then(() => {
        return ({ error: false })
        // dispatch(loadOrderData())
      }).catch(() => {
        return ({ error: true })
      })
  }
}
