import Axios from '../../../utils/axios'

export const loadContactUsData = (data) => {
  return dispatch => {
    Axios.get('/admin/inquiry-messages', dispatch, 'GET_CONTACT_US_DATA', data)
  }
}

export const updateStatus = (id, status) => {
  const submitData = {
    status
  }
  return dispatch => {
    Axios.post(`/admin/update-inquiry-message/${id}`, dispatch, 'POST_UPDATE_STATUS', submitData).then((response) => {
      if (response.type === 'POST_UPDATE_STATUS_FULFILLED') dispatch(loadContactUsData())
      return response
    })
  }
}

export const updateCosts = (id, costs) => {
  const submitData = {
    cost: costs
  }
  return dispatch => {
    Axios.post(`/admin/update-inquiry-message/${id}`, dispatch, 'POST_UPDATE_COSTS', submitData).then((response) => {
      if (response.type === 'POST_UPDATE_COSTS_FULFILLED') dispatch(loadContactUsData())
      return response
    })
  }
}

export const updateVolume = (id, volume) => {
  const submitData = {
    volume
  }
  return dispatch => {
    Axios.post(`/admin/update-inquiry-message/${id}`, dispatch, 'POST_UPDATE_VOLUME', submitData).then((response) => {
      if (response.type === 'POST_UPDATE_VOLUME_FULFILLED') dispatch(loadContactUsData())
      return response
    })
  }
}