import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import login from '../components/Login/reducers'
import myPermissions from './myPermissions'
import notifications from './notifications'
import requestData from './notifications'
import product from '../components/Products/reducers'
import productVariants from '../components/ProductVariants/reducers'
import brand from '../components/Brands/reducers'
import distributor from '../components/Distributors/reducers/index'
import distributorOrder from '../components/DistributorOrders/reducers'
import articleAbout from '../components/Pages/AboutUsPage/reducers'
import homepage from '../components/Pages/Homepage/reducers'
import feedback from '../components/Reports/Feedback/reducers'
import userTypes from '../components/UserTypes/reducers'
import adminManagement from '../components/AdminManagement/reducers'
import users from '../components/Users/reducers'
import roles from '../components/UserTypes/reducers/roles'
import dashboard from '../components/Dashboard/reducers'
import inquiries from '../components/InquiryDashboard/reducers'
import FAQ from '../components/FAQ/reducers'
import inquiryDb from '../components/InquiryDB/reducers'
import contactUs from '../components/ContactUs/reducers'
import containerBreakdown from '../components/ContainerConfiguration/reducers'
import auditTrail from '../components/AuditTrail/reducers'
// import users from '../../Users/reducers'
// import distributorList from '.'
// import roles from '../../UserTypes/reducers/roles'

const rootReducer = combineReducers({
  form,
  login,
  myPermissions,
  product,
  productVariants,
  brand,
  distributor,
  distributorOrder,
  articleAbout,
  homepage,
  feedback,
  userTypes,
  adminManagement,
  users,
  roles,
  dashboard,
  inquiries,
  notifications,
  requestData,
  FAQ,
  inquiryDb,
  contactUs,
  containerBreakdown,
  auditTrail
})

export default rootReducer;
