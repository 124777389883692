import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
  submitForgotPassword
} from './actions';
import Page from './Page';

class ForgotPassword extends Component {
  render() {
    return (
      <Page {...this.props} />
    );
  }
}

const ForgotPasswordForm = Form.create({ name: 'forgotPassword_form' })(ForgotPassword);

function mapStateToProps(state) {
  return {
    // loggingIn: state.login.loggingIn,
    // loggedIn: state.login.loggedIn,
    // loginRejected: state.login.loginRejected
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitForgotPassword: (data) => dispatch(submitForgotPassword(data))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm));