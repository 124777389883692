import moment from 'moment'
import { getName } from 'country-list'
import * as _ from "lodash";

let initialData = {
  distributorLoading: false,
  distributorsData: [],
  distributorPricelistLoading: false,
  distributorPricelistsData: [],
  pricelistByProductLoading: false,
  pricelistByProductData: [],
  productVariantData: [],
  productVariantLoading: false,
  searchProductVariantData: [],
  searchProductVariantLoading: false,
  productVariantEditPricelist: false,
  showHidePricelistLoading: false,
  productsData: [],
  productsLoading: false
};

export default function distributorList(state = initialData, action) {
  switch (action.type) {
    case "GET_DISTRIBUTOR_DATA":
      return {
        ...state,
        distributorLoading: true
      };
    case "GET_DISTRIBUTOR_DATA_FULFILLED":
      action.payload.data.map((data, i) => {
        data.status = data.status !== 0 ? 'Active' : 'Inactive'
        data.created_at_whole = moment(data.created_at).format("MMMM DD YYYY")
        data.created_at_number = moment(data.created_at).format("MM/DD/YYYY")
      })
      return {
        ...state,
        distributorsData: action.payload,
        distributorLoading: false
      };
    case "GET_DISTRIBUTOR_DATA_REJECTED":
      return {
        ...state,
        distributorsData: "",
        distributorLoading: false
      };

    case "GET_DISTRIBUTOR_PRICELIST_DATA":
      return {
        ...state,
        distributorPricelistLoading: true
      };
    case "GET_DISTRIBUTOR_PRICELIST_DATA_FULFILLED":
      return {
        ...state,
        distributorPricelistsData: action.payload,
        distributorPricelistLoading: false
      };
    case "GET_DISTRIBUTOR_PRICELIST_DATA_REJECTED":
      return {
        ...state,
        distributorPricelistsData: "",
        distributorPricelistLoading: false
      };

    case "GET_PRODUCTS_DATA":
      return {
        ...state,
        productsLoading: true
      };
    case "GET_PRODUCTS_DATA_FULFILLED":
      action.payload.map(data => {
        data.currentActiveKey = data.id
      })

      return {
        ...state,
        productsData: action.payload,
        productsLoading: false
      };
    case "GET_PRODUCTS_DATA_REJECTED":
      return {
        ...state,
        productsData: "",
        productsLoading: false
      };


    case "POST_VARIANT_BY_PRODUCT_DATA":
      return {
        ...state,
        pricelistByProductLoading: true
      };
    case "POST_VARIANT_BY_PRODUCT_DATA_FULFILLED":
      action.payload.filter(data => data.status === 1)
      return {
        ...state,
        pricelistByProductData: action.payload,
        pricelistByProductLoading: false
      };
    case "POST_VARIANT_BY_PRODUCT_DATA_REJECTED":
      return {
        ...state,
        pricelistByProductData: "",
        pricelistByProductLoading: false
      };

    case "POST_PRODUCT_VARIANT_SEARCH_DATA":
      return {
        ...state,
        pricelistByProductLoading: true
      };
    case "POST_PRODUCT_VARIANT_SEARCH_DATA_FULFILLED":
      if (_.some(action.payload, (data) => { return data === null })) {
        action.payload = _.filter(_.values(action.payload), (data) => { return data !== null })
        return {
          ...state,
          pricelistByProductData: action.payload,
          pricelistByProductLoading: false
        };
      } else {
        return {
          ...state,
          pricelistByProductData: action.payload,
          pricelistByProductLoading: false
        };
      }

    case "POST_PRODUCT_VARIANT_SEARCH_DATA_REJECTED":
      return {
        ...state,
        pricelistByProductData: "",
        pricelistByProductLoading: false
      };

    case "GET_PRODUCT_VARIANT_DATA":
      return {
        ...state,
        productVariantLoading: true,
        productVariantData: []
      };
    case "GET_PRODUCT_VARIANT_DATA_FULFILLED":
      return {
        ...state,
        productVariantData: action.payload,
        productVariantLoaded: true,
        productVariantLoading: false
      };
    case "POST_EDIT_PRICE_LIST":
      return {
        ...state,
        productVariantEditPricelist: true
      };

    case "POST_EDIT_PRICE_LIST_FULFILLED":
      return {
        ...state,
        productVariantEditPricelist: false
      };
    case "POST_EDIT_PRICE_LIST_REJECTED":
      return {
        ...state,
        productVariantEditPricelist: false
      };

    case "POST_SHOW_HIDE_PRICELIST":
      return {
        ...state,
        showHidePricelistLoading: true
      };

    case "POST_SHOW_HIDE_PRICELIST_FULFILLED":
      return {
        ...state,
        showHidePricelistLoading: false
      };
    case "POST_SHOW_HIDE_PRICELIST_REJECTED":
      return {
        ...state,
        showHidePricelistLoading: false
      };

    default:
      return {
        ...state
      };
  }
}
