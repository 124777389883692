const initialData = {
  articleLoading: false,
  articleLoaded: false,
  articleRejected: false,
  articleData: []

}
export default function aboutArticle(state = initialData, action) {
  switch (action.type) {
    case 'GET_ARTICLE':
      return {
        ...state,
        articleLoading: true,
        articleLoaded: false,
        articleRejected: false
      }
    case 'GET_ARTICLE_FULFILLED':
      return {
        ...state,
        articleLoading: false,
        articleLoaded: true,
        articleData: action.payload
      }
    case 'GET_ARTICLE_REJECTED':
      return {
        ...state,
        articleLoading: false,
        articleRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
