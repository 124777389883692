import Axios from 'axios'
import { headers } from '../../../../utils/token';

const GET_FEEDBACK = 'GET_FEEDBACK'
const GET_FEEDBACK_FULFILLED = 'GET_FEEDBACK_FULFILLED'
const GET_FEEDBACK_REJECTED = 'GET_FEEDBACK_REJECTED'

const POST_CANCEL_FEEDBACK = 'POST_CANCEL_FEEDBACK'
const POST_CANCEL_FEEDBACK_FULFILLED = 'POST_CANCEL_FEEDBACK_FULFILLED'
const POST_CANCEL_FEEDBACK_REJECTED = 'POST_CANCEL_FEEDBACK_REJECTED'

const API_URL = process.env.REACT_APP_API_URL

export const loadFeedbackData = (data) => {
  return dispatch => {
    dispatch({
      type: GET_FEEDBACK,
      payload: {}
    })
    return Axios.get(`${API_URL}/feedback`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_FEEDBACK_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_FEEDBACK_REJECTED,
          payload: error
        })
      })
  }
}


export const ignoreComment = (id) => {
  const data = {
    is_disabled: 1
  }
  return dispatch => {
    dispatch({
      type: POST_CANCEL_FEEDBACK,
      payload: {}
    })
    return Axios.post(`${API_URL}/cancel-feedback/${id}`, data, { headers: headers })
      .then(function (response) {
        dispatch(loadFeedbackData())
        dispatch({
          type: POST_CANCEL_FEEDBACK_FULFILLED,
          payload: response.data
        })
        return { error: false, result: response }
      })
      .catch(function (error) {
        dispatch({
          type: POST_CANCEL_FEEDBACK_REJECTED,
          payload: error
        })
        return { error: true, result: error }
      })
  }
}
