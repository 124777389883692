export const slugify = name => {
  return name.replace(/ /g, '-').toLowerCase()
}

export const serialize = (json) => {
  return '?' +
    Object.keys(json).map(function (key) {
      return encodeURIComponent(key) + '=' +
        encodeURIComponent(json[key]);
    }).join('&');
}

export const ucFirst = myString => {
  const explode = myString.split(' ')
  let joinString = ''
  explode.forEach(row => {
    joinString += row.slice(0, 1).toUpperCase() + row.slice(1) + ' '
  })

  return joinString
}

export const uuid = () => 'ss-s-s-s-sss'.replace(/s/g, s4)


function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

