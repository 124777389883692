import React, { Component } from 'react';
import { Form, Icon, Row, Table, Button, Col, Checkbox, Layout, Menu, Dropdown, Modal, Spin } from 'antd';
import './index.scss';
import ModalForm from './components/ModalForm';
import ModalView from './components/ModalView';
import { EditorState, ContentState, convertFromHTML, ConvertToHTML, ConvertFromRawDraftContent, draftToMarkdown, convertToRaw } from 'draft-js';

const ButtonGroup = Button.Group;

class AboutUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFormModal: false,
      showViewModal: false,
      selectedRow: null,
      showFormPricelistModal: false,
      showViewPricelistModal: false,
      showEditPriceListModal: false,
      showAddEditModal: false,
      modalType: '',
      tableData: []
    };
  }
  componentDidMount() {
    this.props.onLoadAboutArticle()
  }


  handleSubmit = e => {
    e.preventDefault();

    const { onSubmitAddArticle, onSubmitUpdateArticle, form } = this.props
    const { selectedRow } = this.state;

    form.validateFields(async (err, values) => {
      const description = (typeof (values.description) === 'object') ? JSON.stringify(values.description) : values.description
      if (!err) {
        let params = {
          ...values,
          description
        }

        if (selectedRow) {
          params.id = selectedRow.id;
          await onSubmitUpdateArticle(params);
        } else {
          await onSubmitAddArticle(params);
        }

        this.props.form.resetFields();
        this.handleCloseModal()
      }
    });
  };

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
      showAddEditModal: true,
      modalType: 'add',
      selectedRow: null
    });
  };

  handleShowEditFormModal = row => {
    this.setState({
      showFormModal: true,
      selectedRow: row
    });
  };

  handleShowViewModal = row => {
    this.setState({
      showViewModal: true,
      selectedRow: row
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      showAddEditModal: false,
      showEditPriceListModal: false,
      selectedRow: null
    });
  };

  handleChangeStatus = (row, status) => {
    const params = {
      id: row.id,
      status: status
    };
    this.props.onSubmitUpdateArticle(params)
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update Article.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Article has been updated successfully',
            content: ''
          })
        }
      })
  };

  handleEditDistributor = (row) => {
    this.setState({ showAddEditModal: true, selectedRow: row, modalType: 'edit' })
  }

  render() {

    const dataSource = this.props.tableData
    const { form: { getFieldDecorator } } = this.props
    const { showAddEditModal, showViewModal, selectedRow, } = this.state
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => {
          const x = a.title.toLowerCase();
          const y = b.title.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      {
        title: 'Sub Title',
        dataIndex: 'sub_title',
        key: 'sub_title',
        sorter: (a, b) => {
          const x = a.sub_title.toLowerCase();
          const y = b.sub_title.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => {
          const x = a.description.toLowerCase();
          const y = b.description.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (tags, row) => {
          const blocks = (JSON.parse(row.description).blocks)
          const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
          return <span>{value.substring(0, 150)}...</span>
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 200,
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }],
        onFilter: (value, record) => record.status === value,
        render: (tags, row) => {
          const status = row.status
          return (status === 1) ? <span>Active</span> : <span>Inactive</span>
        }
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 200,
        render: (id, row) => {
          return (
            <Dropdown overlay={(
              <Menu>
                {(row.status === 1) ?
                  <Menu.Item onClick={() => this.handleChangeStatus(row, 0)}>Deactivate</Menu.Item> :
                  <Menu.Item onClick={() => this.handleChangeStatus(row, 1)}>Activate</Menu.Item>
                }
                <Menu.Item onClick={() => this.handleShowViewModal(row)}>View Details</Menu.Item>
                <Menu.Item onClick={() => this.handleEditDistributor(row)} > Edit Article</Menu.Item>
              </Menu>
            )}>
              <Button>
                Actions <Icon type="down" />
              </Button>
            </Dropdown>
          )
        }
      }
    ];
    return (
      <Layout>
        <Layout.Content style={{ padding: 20 }}>
          <Row>
            <Col span={12} className="page-title">
              Abous Us Website
              </Col>
            <Col span={12} align="right">
              <ButtonGroup style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={this.handleShowFormModal} style={{ margin: '10px' }} ><Icon type="user-add" /> Add Article</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                dataSource={dataSource}
                columns={columns}
                loading={this.props.articleLoading}
                onChange={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0;
                }}
              />
            </Col>
          </Row>
        </Layout.Content>
        {showAddEditModal &&
          <ModalForm
            showFormModal={showAddEditModal}
            handleCloseModal={this.handleCloseModal}
            handleSubmit={this.handleSubmit}
            imageUploader={this.imageUploader}
            selectedRow={selectedRow}
            getFieldDecorator={getFieldDecorator}
          // isLoading={brandLoading}
          />
        }
        <ModalView
          showFormModal={showViewModal}
          handleCloseModal={this.handleCloseModal}
          selectedRow={selectedRow}
        />
      </Layout>
    )
  }
}

const AboutUsForm = Form.create({ name: 'about_us_form' })(AboutUsPage);

export default AboutUsForm