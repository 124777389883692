import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Layout, Spin, Modal, Row, Col } from 'antd';
import { Link } from 'react-router-dom'
import './index.scss';

class ForgotPassword extends Component {

  state = {
    loading: false
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true })
        values['portal'] = 'admin';
        this.props.onSubmitForgotPassword(values).then(response => {
          if (response.error) {
            Modal.error({
              title: 'Unable to process the request. Please try again.',
              content: response.message === 2003 ? "The email do not exist." : null,
              // icon: <img src={error} alt="error" />
            })
          } else {
            Modal.success({
              title: 'Success! We have sent you an e-mail. You will be redirected to login page. Thank you.',
              content: '',
              // icon: <img src={success} alt="success" />,
              onOk: () => this.props.history.push('/')
            })
            this.props.form.resetFields()
          }
          this.setState({ loading: false })
        })
      }
    });
  };

  // componentWillReceiveProps (nextProps) {
  //   if((this.props.loggedIn !== nextProps.loggedIn) && nextProps.loggedIn ){
  //     this.props.history.push('/dashboard')
  //   }
  // }

  componentDidMount() {
    // const { history } = this.props
    // history.push('/forgot-password')
  }

  render() {
    const { loading } = this.state
    const { form: { getFieldDecorator }, loggingIn, loginRejected } = this.props;
    return (
      <Layout>
        <div className='login-container'>
          <div className='login-form'>
            {/* <Spin spinning={loggingIn}> */}
            <div className='login-header'>
              <img alt='login-logo' src={'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Universal_Robina_logo_2016.svg/1200px-Universal_Robina_logo_2016.svg.png'} />
              <span>URC Administrator</span>
            </div>
            {/* {loginRejected &&
                <div className='login-error-message'>
                  <span>Login Failed: Invalid Credentials</span>
                </div>
              } */}
            <Form onSubmit={this.handleSubmit}>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Please input your email for account recovery!' }],
                })(
                  <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                  />,
                )}
              </Form.Item>
              {/* <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Password"
                    />,
                  )}
                </Form.Item> */}
              <Form.Item>
                <Row>
                  <Col md={12}>
                    <Button type="primary" loading={loading} htmlType="submit" className="login-form-button">
                      {loading ? null : "Submit"}
                    </Button>
                  </Col>
                  <Col md={12} style={{ textAlign: 'right' }}>
                    <Button type="primary">
                      <Link to='/'>Back</Link>
                    </Button>
                  </Col>
                </Row>
                {/* <br /> */}
                {/* {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(<Checkbox>Remember me</Checkbox>)} */}
              </Form.Item>
            </Form>
            {/* </Spin> */}
          </div>
        </div>
      </Layout>
    );
  }
}

const ForgotPasswordForm = Form.create({ name: 'forgotPassword_form' })(ForgotPassword);

export default ForgotPasswordForm;