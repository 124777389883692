import React, { Component } from 'react';
import { Form, Icon, Button, Layout, Row, Col, Table, Dropdown, Menu, Input, Modal } from 'antd';
import moment from 'moment';
import ModalForm from './components/ModalForm';
import ModalView from './components/ModalView';

const { Content } = Layout;
const { Search } = Input;
const ButtonGroup = Button.Group;

class User extends Component {
  initialState = {
    showFormModal: false,
    showViewModal: false,
    selectedRow: null
  }

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.imageUploader = React.createRef();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { onSubmitAddBrand, onSubmitUpdateUser } = this.props
    const { selectedRow } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values
        }

        let media = null;
        let imageUrl =
          this.imageUploader.current === null
            ? ""
            : this.imageUploader.current.getImageUrl();
        media =
          this.imageUploader.current === null
            ? ""
            : await this.imageUploader.current.handleUploadRequest();
        params.image = media ? media.url : imageUrl;

        if (selectedRow) {
          params.id = selectedRow.id;
          await onSubmitUpdateUser(params).then((response) => {
            if (response.error) {
              Modal.success({
                title: 'Unable to update Brands.',
                content: ''
              })
            } else {
              Modal.success({
                title: 'Brands has been updated successfully',
                content: ''
              })
            }
          })
        } else {
          await onSubmitAddBrand(params).then((response) => {
            if (response.error) {
              Modal.success({
                title: 'Unable to Add Brands.',
                content: ''
              })
            } else {
              Modal.success({
                title: 'Brands has been added successfully',
                content: ''
              })
            }
          })
        }

        this.props.form.resetFields();
        this.handleCloseModal()
      }
    });
  };

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleShowEditFormModal = row => {
    this.setState({
      showFormModal: true,
      selectedRow: row
    });
  };

  handleShowViewModal = row => {
    this.setState({
      showViewModal: true,
      selectedRow: row
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      selectedRow: null
    });
  };

  changeStatus = (row, status) => {
    const params = {
      id: row.id,
      status: status
    };
    this.props.onSubmitUpdateUser(params)
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update status of user.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'User status has been updated successfully',
            content: ''
          })
        }
      })
  }

  componentDidMount() {
    this.props.onLoadUsersData()
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    //function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    }
    const { usersData } = this.props
    const newArrayObj = usersData
    //if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ tableData: newArrayObj })
    }
    let distributorArray = []
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach(keys => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
        let isFound = (rowItemKeys) && rowItemKeys.toLowerCase().includes(searchTerm.toLowerCase())
        if (isFound) distributorArray.push(rowItem)
      })
    })
    //Removed Duplicate Entry in Array.
    const newArrayElement = distributorArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
    this.setState({ tableData: newArrayElement })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.usersData !== nextProps.usersData) {
      this.setState({ tableData: nextProps.usersData })
    }
  }

  render() {
    const { showFormModal, showViewModal, selectedRow, tableData } = this.state
    const { brandLoading, usersData, form: { getFieldDecorator } } = this.props

    const dataSource = tableData

    dataSource.sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix())

    const columns = [
      {
        title: 'Name',
        dataIndex: 'username',
        key: 'username  ',
      },
      {
        title: 'Created Date',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        width: 200,
        render: created_at => {
          return <span>{moment(created_at).format("MMM. DD, YYYY")}</span>
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 200,
        render: (tags, row) => {
          const status = row.status
          return (status === 1) ? <span>Active</span> : <span>Inactive</span>
        }
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 200,
        render: (id, row) => {
          return (
            <Dropdown overlay={(
              <Menu>
                {(row.status === 1) ?
                  <Menu.Item onClick={() => this.changeStatus(row, 0)}>Deactivate</Menu.Item> :
                  <Menu.Item onClick={() => this.changeStatus(row, 1)}>Activate</Menu.Item>
                }
                <Menu.Item onClick={() => this.handleShowEditFormModal(row)}>Edit Details</Menu.Item>
                <Menu.Item onClick={() => this.handleShowViewModal(row)}>View Details</Menu.Item>
              </Menu>
            )}>
              <Button>
                Actions <Icon type="down" />
              </Button>
            </Dropdown>
          )
        }
      }
    ];

    return (
      <div>
        <Layout>
          <Content style={{ background: '#FCF9F4', padding: 20 }}>
            <Row>
              <Col span={12} className="page-title">
                Users
              </Col>
              <Col span={12} align="right">
                <ButtonGroup style={{ display: 'flex' }}>
                  <Search placeholder="Search" onSearch={(value) => this.handleSearch(value, ['name', 'created_at', 'material_code'])} enterButton style={{ margin: '10px' }} />
                  <Button type="primary" onClick={this.handleShowFormModal} style={{ margin: '10px' }} ><Icon type="plus" /> Add Brand</Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  loading={brandLoading}
                  onChange={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0;
                  }}
                  rowClassName={(row, index) => {
                    if (index % 2 !== 0) {
                      return "row-even";
                    }

                    return "row-odd";
                  }}
                />
              </Col>
            </Row>
          </Content>
        </Layout>

        <ModalForm
          showFormModal={showFormModal}
          handleCloseModal={this.handleCloseModal}
          handleSubmit={this.handleSubmit}
          imageUploader={this.imageUploader}
          selectedRow={selectedRow}
          getFieldDecorator={getFieldDecorator}
          isLoading={brandLoading}
        />

        <ModalView
          showFormModal={showViewModal}
          handleCloseModal={this.handleCloseModal}
          selectedRow={selectedRow}
        />
      </div>
    )
  }
}

const UserForm = Form.create({ name: 'user_form' })(User);

export default UserForm;
