import Axios from '../../../utils/axios'

export const loadBrandsData = (data) => {
  return dispatch => {
    Axios.get('/admin/brands-with-products', dispatch, 'GET_BRANDS_DATA', data)
  }
}

export const loadActiveBrandsData = (data) => {
  return dispatch => {
    Axios.get('/admin/active-brands-with-products', dispatch, 'GET_BRANDS_DATA', data)
  }
}

export const submitAddBrand = (data, searchParams) => {
  return dispatch => {
    dispatch({
      type: 'POST_BRANDS_DATA'
    })
    return Axios.post(`/admin/brand`, dispatch, 'POST_BRANDS_DATA', data)
      .then((response) => {
        dispatch(loadBrandsData(searchParams))
        return response
      })
  }
}

export const submitUpdateBrand = (data, searchParams) => {
  return dispatch => {
    dispatch({
      type: 'POST_UPDATE_BRANDS_DATA'
    })
    return Axios.post(`/admin/brand/${data.id}`, dispatch, 'POST_UPDATE_BRANDS_DATA', data)
      .then((response) => {
        dispatch(loadBrandsData(searchParams))
        return response
      })
  }
}

export const submitDisableBrand = (data, searchParams) => {

  return dispatch => {
    dispatch({
      type: 'POST_DISABLED_BRANDS_DATA'
    })
    return Axios.post(`/admin/disable-brand/${data.id}`, dispatch, 'POST_DISABLED_BRANDS_DATA', data)
      .then((response) => {
        dispatch(loadBrandsData(searchParams))
        return response
      })
  }
}
