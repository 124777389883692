const initialData = {
  portfolioBrandLoading: false,
  portfolioBrandLoaded: false,
  portfolioBrandRejected: false,
  portfolioBrandData: []

}
export default function aboutArticle(state = initialData, action) {
  switch (action.type) {
    case 'GET_PORTFOLIO_BRAND':
      return {
        ...state,
        portfolioBrandLoading: true,
        portfolioBrandLoaded: false,
        portfolioBrandRejected: false
      }
    case 'GET_PORTFOLIO_BRAND_FULFILLED':
      return {
        ...state,
        portfolioBrandLoading: false,
        portfolioBrandLoaded: true,
        portfolioBrandData: action.payload
      }
    case 'GET_PORTFOLIO_BRAND_REJECTED':
      return {
        ...state,
        portfolioBrandLoading: false,
        portfolioBrandRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
