import axios from "axios";
import { headers } from '../../../utils/token';
import Axios from '../../../utils/axios'

const GET_PRODUCTS_DATA = 'GET_PRODUCTS_DATA';
const GET_PRODUCTS_DATA_FULFILLED = 'GET_PRODUCTS_DATA_FULFILLED';
const GET_PRODUCTS_DATA_REJECTED = 'GET_PRODUCTS_DATA_REJECTED'

const GET_PRODUCT_VARIANT_DATA = 'GET_PRODUCT_VARIANT_DATA';
const GET_PRODUCT_VARIANT_DATA_FULFILLED = 'GET_PRODUCT_VARIANT_DATA_FULFILLED';
const GET_PRODUCT_VARIANT_DATA_REJECTED = 'GET_PRODUCT_VARIANT_DATA_REJECTED'

const POST_VARIANT_BY_PRODUCT_DATA = 'POST_VARIANT_BY_PRODUCT_DATA';
const POST_VARIANT_BY_PRODUCT_DATA_FULFILLED = 'POST_VARIANT_BY_PRODUCT_DATA_FULFILLED';
const POST_VARIANT_BY_PRODUCT_DATA_REJECTED = 'POST_VARIANT_BY_PRODUCT_DATA_REJECTED'

const POST_PRODUCT_VARIANT_SEARCH_DATA = 'POST_PRODUCT_VARIANT_SEARCH_DATA';
const POST_PRODUCT_VARIANT_SEARCH_DATA_FULFILLED = 'POST_PRODUCT_VARIANT_SEARCH_DATA_FULFILLED';
const POST_PRODUCT_VARIANT_SEARCH_DATA_REJECTED = 'POST_PRODUCT_VARIANT_SEARCH_DATA_REJECTED'

const API_URL = process.env.REACT_APP_API_URL

export const submitAddSubAccount = (data) => {
    return dispatch => {
        return Axios.post(`/admin/distributor/${data.distributor_id}/employee`, dispatch, 'POST_SUB_ACCOUNT', data)
            .then((response) => {
                return response
            })
    }
}

export const loadUserRolesData = (data) => {
    return dispatch => {
        Axios.get('/admin/role', dispatch, 'GET_USER_ROLES', data)
    }
}

export const loadDistributorsData = (data) => {
    return dispatch => {
        return Axios.get('/admin/distributors', dispatch, 'GET_DISTRIBUTOR_DATA', data)
    }
}

export const submitAddDistributor = (data, searchParams) => {
    return dispatch => {
        Axios.post(`/admin/distributor`, dispatch, 'POST_DISTRIBUTOR_DATA', data)
            .then(() => {
                dispatch(loadDistributorsData(searchParams))
            })
    }
}

export const submitUpdateDistributor = (data, searchParams) => {

    const url = data.id === '' ? `/admin/distributor`:  `/admin/distributor/${data.id}`;

    return dispatch => {
        return Axios.post(url, dispatch, 'POST_DISTRIBUTOR_DATA', data)
            .then((response) => {
                dispatch(loadDistributorsData(searchParams))
                return response
            })
    }
}

export const submitEditPriceList = (data) => {
    return dispatch => {
        return Axios.post(`/admin/distributor-pricelist-update`, dispatch, 'POST_EDIT_PRICE_LIST_DATA', data)
            .then((response) => {
                return response;
            })
    }
}

export const submitShowHideVariant = (data) => {
    return dispatch => {
        return Axios.post(`/admin/product-variant-is-hidden`, dispatch, 'POST_EDIT_SHOW_HIDE_VARIANT_DATA', data)
            .then((response) => {
                return response;
            })
    }
}

export const loadProductsData = (data) => {
    return dispatch => {
        Axios.get(`/admin/products`, dispatch, 'GET_PRODUCTS_DATA', data)
    }
}

export const loadProductVariantData = (data) => {
    return dispatch => {
        Axios.post(`/admin/product-variants-by-product`, dispatch, 'POST_VARIANT_BY_PRODUCT_DATA', data)
            // .then((response) => {
            //     return response
            // })
            .then(function (productVariants) {
                productVariants.response.data.filter(data => data.status === 1).map((variantData, i) => {
                    variantData.pricelist.map(pricelist => {
                        pricelist.selling_price = pricelist.selling_price === null ? 0 : pricelist.selling_price
                        pricelist.special_code = pricelist.special_code === null ? '' : pricelist.special_code
                        pricelist.status = pricelist.status === null ? false : pricelist.status
                    })
                })
                // dispatch({
                //     type: POST_VARIANT_BY_PRODUCT_DATA_FULFILLED,
                //     payload: productVariants.response.data
                // })
            })
        // .catch(function (error) {
        //     dispatch({
        //         type: POST_VARIANT_BY_PRODUCT_DATA_REJECTED,
        //         payload: error
        //     });
        // })
    }
}

export const searchProductVariant = (data) => {
    return dispatch => {
        Axios.post(`/admin/product-variants-search`, dispatch, 'POST_PRODUCT_VARIANT_SEARCH_DATA', data)
            .then((response) => {
                return response
            })
        // .then(function (searchResult) {
        //     Axios.get(`/admin/active-products/`, dispatch, 'GET_PRODUCTS_DATA', data)
        //     .then(function (productMatch) {
        //         const searchResultProductIDs = [...new Set(searchResult.response.data.map(result => result.product_id))]

        //         productMatch.response.data.filter(product => searchResultProductIDs.includes(product.id))
        //         // dispatch({
        //         //     type: 'GET_PRODUCTS_DATA_FULFILLED',
        //         //     payload: productMatch.response.data.filter(product => searchResultProductIDs.includes(product.id))
        //         // })
        //     })
        //     // dispatch({
        //     //     type: 'POST_PRODUCT_VARIANT_SEARCH_DATA_FULFILLED',
        //     //     payload: searchResult.response.data
        //     // })

        //     return searchResult
        // })
        // .then((searchResult) => {
        //     searchResult.response.data.map(variantData => {
        //         variantData.pricelist.filter(pricelist => pricelist.distributor_id === data.distributor_id)
        //     })
        //     // dispatch({
        //     //     type: POST_PRODUCT_VARIANT_SEARCH_DATA_FULFILLED,
        //     //     payload: searchResult.response.data


        //     // })

        // })
        // // .catch(function (error) {
        // //     dispatch({
        // //         type: POST_PRODUCT_VARIANT_SEARCH_DATA_REJECTED,
        // //         payload: error
        // //     });
        // // })
    }
}



// export const submitUpdateDistributorPricelist = (data) => {
//     return dispatch => {
//         return Axios.post(`/admin/distributor-pricelist/${data.id}`, dispatch, 'POST_DISTRIBUTOR_PRICE_LIST_DATA', data)
//             .then((response) => {
//                 dispatch(loadDistributorsData())
//                 return response
//             })
//     }
// }

// export const submitUpdateDistributorPricelist = (data) => {
//     return dispatch => {
//         return Axios.post(`/admin/distributor-pricelist/${data.id}`, dispatch, 'POST_DISTRIBUTOR_PRICE_LIST_DATA', data)
//             .then((response) => {
//                 dispatch(loadDistributorsData())
//                 return response
//             })
//     }
// }

// export const loadProductVariantData = (id) => {
//     return dispatch => {
//         dispatch({
//             type: GET_PRODUCT_VARIANT_DATA,
//             payload: {}
//         })
//         return axios.post(`${API_URL}/admin/product-variants-by-product`, { headers: headers })
//             .then(function (productVariants) {
//                 axios.get(`${API_URL}/distributor-pricelist/${id}`, { headers: headers })
//                     .then(function (distributorPricelist) {
//                         productVariants.data.forEach((variantData, i) => {
//                             let priceList = {
//                                 selling_price: 0,
//                                 special_code: '',
//                                 status: 0
//                             };

//                             distributorPricelist.data.forEach((pricelistData) => {
//                                 if (variantData.id === pricelistData.product_variant_id) {
//                                     priceList = {
//                                         selling_price: typeof (pricelistData.selling_price) !== null ? pricelistData.selling_price : 0,
//                                         special_code: typeof (pricelistData.special_code) !== null ? pricelistData.special_code : '',
//                                         status: typeof (pricelistData.status) !== null ? pricelistData.status : 0
//                                     };
//                                 }
//                             });

//                             productVariants.data[i].priceList = priceList;
//                         })
//                         dispatch({
//                             type: GET_PRODUCT_VARIANT_DATA_FULFILLED,
//                             payload: productVariants.data
//                         })
//                     })
//             })
//             .catch(function (error) {
//                 dispatch({
//                     type: GET_PRODUCT_VARIANT_DATA_REJECTED,
//                     payload: error
//                 });
//             })
//     }
// }
