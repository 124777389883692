import moment from 'moment'
const initialData = {
  brandLoading: false,
  disabledBrandLoading: false,
  updateBrandLoading: false,
  brandsData: []
}

export default function brand(state = initialData, action) {
  switch (action.type) {
    case 'GET_BRANDS_DATA':
      return {
        ...state,
        brandLoading: true
      }
    case 'GET_BRANDS_DATA_FULFILLED':
      action.payload.data.map((data, i) => {
        data.created_at = moment(data.created_at).format("MMMM DD, YYYY")
        data.created_at_whole = moment(data.created_at).format("MMMM DD YYYY")
        data.status = data.status === 1 ? 'Active' : 'Inactive'
        data.is_disabled = data.is_disabled === 1 ? 'Disabled' : 'Enabled'
      })
      return {
        ...state,
        brandsData: action.payload,
        brandLoading: false
      }
    case 'GET_BRANDS_DATA_REJECTED':
      return {
        ...state,
        brandsData: '',
        brandLoading: false
      }

    case 'POST_BRANDS_DATA':
      return {
        ...state,
        brandLoading: true
      }
    case 'POST_BRANDS_DATA_FULFILLED':
      return {
        ...state,
        brandLoading: false
      }
    case 'POST_BRANDS_DATA_REJECTED':
      return {
        ...state,
        brandLoading: false
      }


    case 'POST_DISABLED_BRANDS_DATA':
      return {
        ...state,
        disabledBrandLoading: true
      }
    case 'POST_DISABLED_BRANDS_DATA_FULFILLED':
      return {
        ...state,
        disabledBrandLoading: false
      }
    case 'POST_DISABLED_BRANDS_DATA_REJECTED':
      return {
        ...state,
        disabledBrandLoading: false
      }

    case 'POST_UPDATE_BRANDS_DATA':
      return {
        ...state,
        updateBrandLoading: true
      }
    case 'POST_UPDATE_BRANDS_DATA_FULFILLED':
      return {
        ...state,
        updateBrandLoading: false
      }
    case 'POST_UPDATE_BRANDS_DATA_REJECTED':
      return {
        ...state,
        updateBrandLoading: false
      }
    default:
      return {
        ...state
      }
  }
}
