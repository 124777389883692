import React from 'react'
import { Row, Col, Button, Form, Modal, Layout, Input, Card } from 'antd';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

import draftToHtml from 'draftjs-to-html'

const { Content } = Layout;

export default function ModalForm(props) {
  const { selectedRow } = props;

  if (selectedRow === null) {
    return "";
  }

  const content = convertFromRaw(JSON.parse(selectedRow.description));

  return (
    <Modal
      title="View Details"
      visible={props.showFormModal}
      onCancel={props.handleCloseModal}
      centered={true}
      footer={null}
      width={800}
    >
      <Layout>
        <Content className='contact-form-container'>
          <Row>
            <Form onSubmit={props.handleSubmit} className="login-form">
              <Card>
                <Col lg={24}>
                  <Form.Item label="Title">
                    <Input value={selectedRow.title} disabled={true} />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item label="Sub Title">
                    <Input value={selectedRow.sub_title} disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Status">
                    <Input value={selectedRow.status ? "Active" : "Inactive"} disabled={true} />
                  </Form.Item>
                </Col>
                <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(EditorState.createWithContent(content).getCurrentContent())) }}></div>
              </Card>
              <Col span={24} align="right">
                <Form.Item>
                  <Button key="back" onClick={props.handleCloseModal}>
                    Close
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Row>
        </Content>
      </Layout>
    </Modal>
  )
}
