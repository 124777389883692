import Axios from '../../../utils/axios'

export const loadProductData = (data) => {
  return dispatch => {
    Axios.get('/admin/products', dispatch, 'GET_PRODUCT_DATA', data)
  }
}

export const loadActiveProductData = (data) => {
  return dispatch => {
    Axios.get('/admin/active-products', dispatch, 'GET_PRODUCT_DATA', data)
  }
}

export const submitAddProduct = (data, searchParams) => {
  return dispatch => {
    dispatch({
      type: 'POST_PRODUCT_DATA'
    })
    return Axios.post(`/admin/product`, dispatch, 'POST_PRODUCT_DATA', data)
      .then((response) => {
        dispatch(loadProductData(searchParams))
        return response
      })
  }
}

export const submitUpdateProduct = (data, searchParams) => {
  return dispatch => {
    dispatch({
      type: 'POST_UPDATE_PRODUCT_DATA'
    })
    return Axios.post(`/admin/product/${data.id}`, dispatch, 'POST_UPDATE_PRODUCT_DATA', data)
      .then((response) => {
        dispatch(loadProductData(searchParams))
        return response
      })
  }
}

export const submitDisableProduct = (data) => {
  return dispatch => {
    dispatch({
      type: 'POST_DISABLED_PRODUCT_DATA'
    })
    return Axios.post(`/admin/disable-product/${data.id}`, dispatch, 'POST_DISABLED_PRODUCT_DATA', data)
      .then((response) => {
        dispatch(loadProductData())
        return response
      })
  }
}
