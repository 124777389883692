import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadContactUsData,
    updateStatus,
    updateCosts,
    updateVolume
} from './actions'
import {
    fetchPermissions
} from '../../actions'
import Page from './Page';

class Contact extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const OrderForm = Form.create({ name: 'contact_us' })(Contact);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        contactUsData: state.contactUs.contactUsData,
        contactUsLoading: state.contactUs.contactUsLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadContact: (data) => dispatch(loadContactUsData(data)),
        onUpdateStatus: (id, status) => dispatch(updateStatus(id, status)),
        onUpdateCosts: (id, costs) => dispatch(updateCosts(id, costs)),
        onUpdateVolume: (id, volume) => dispatch(updateVolume(id, volume))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
