import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, Icon, Layout, Select, Input, Dropdown, Menu } from 'antd'
import Reorder, {
    reorder,
    reorderImmutable,
    reorderFromTo,
    reorderFromToImmutable
} from 'react-reorder';

const { Content } = Layout;
const { Search } = Input;
const ButtonGroup = Button.Group;

export default class PortfolioBrand extends Component {
    state = {
        list: []
    }

    componentDidMount() {
        this.props.onLoadPortfolioBrands()
    }

    componentWillReceiveProps(nextProps) {
        nextProps.portfolioBrandData.sort((a, b) => (a.sort_number > b.sort_number) ? 1 : -1)
        if (this.props.portfolioBrandData !== nextProps.portfolioBrandData) {
            this.setState({ list: nextProps.portfolioBrandData })
        }
    }
    onReorder(event, previousIndex, nextIndex, fromId, toId) {
        const itemReorder = reorder(this.state.list, previousIndex, nextIndex).map((data, index) => {
            data.sort_number = index + 1
        })
        this.setState({
            list: reorder(this.state.list, previousIndex, nextIndex)
        });
    }

    onReorderGroup(event, previousIndex, nextIndex, fromId, toId) {
        if (fromId === toId) {
            const list = reorderImmutable(this.state[fromId], previousIndex, nextIndex);

            this.setState({
                [fromId]: list
            });
        } else {
            const lists = reorderFromToImmutable({
                from: this.state[fromId],
                to: this.state[toId]
            }, previousIndex, nextIndex);

            this.setState({
                [fromId]: lists.from,
                [toId]: lists.to
            });
        }
    }

    handleSavePortFolioBrands() {
        let listArray = [];
        this.state.list.map(data => {
            listArray.push({ id: data.id, sort_number: data.sort_number })
        })
        this.props.onSubmitPortfolioBrands(listArray).then((res) => {
            if (res.error) {
                alert('save failed')
            } else {
                alert('saved!!!')
            }
        })
    }

    itemClicked(e) {
    }



    render() {

        return (
            <Layout.Content style={{ padding: 20 }}>
                <Row>
                    <Col span={12} className="page-title">
                        Brand To Display
                    </Col>
                    <Col span={12} align="right">
                        <ButtonGroup style={{ display: 'flex' }}>
                            <Button type="primary" onClick={() => this.handleSavePortFolioBrands()} style={{ margin: '10px' }} ><Icon type="save" /> Save Changes </Button>
                            <Button type="primary" onClick={() => this.handleShowFormModal()} style={{ margin: '10px' }} ><Icon type="plus" /> Add Brand </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <div className='homepage'>
                        <Reorder
                            reorderId="my-list" // Unique ID that is used internally to track this list (required)
                            reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
                            // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
                            // component="ul" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                            placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                            draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                            // lock="vertical" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                            holdTime={0} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                            touchHoldTime={0} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                            mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                            onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
                            autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                            disabled={false} // Disable reordering (optional), defaults to false
                            disableContextMenus={false} // Disable context menus when holding on touch devices (optional), defaults to true
                            placeholder={
                                <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
                            }
                            // callback={(e) => {}}
                            listClass='my-list'
                            itemClass='list-item'
                            itemClicked={this.itemClicked.bind(this)}
                            selected={this.state.selected}
                            selectedKey='uuid'
                            disableReorder={false}
                        >
                            {
                                this.state.list.map((item, index) => {
                                    return (
                                        <div key={index} style={{
                                            margin: 10, border: '1px solid lightgray', borderRadius: 5, cursor: 'pointer',
                                            padding: '12px',
                                            border: '2px solid lightblue',
                                            transformOrigin: '50% 50%',
                                            width: '180px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                <div style={{ width: 'auto' }}>

                                                    {/* <Dropdown overlay={menu}> */}
                                                    <Button type="primary" shape='circle' style={{ margin: 2 }}>  <Icon type="setting" /> </Button>
                                                    <Button type="primary" shape='circle' style={{ margin: 2 }}>  <Icon type="close" /> </Button>
                                                    {/* </Dropdown> */}
                                                </div>
                                            </div>
                                            <div className='brand-content' style={{}} >
                                                <img
                                                    src={`${process.env.REACT_APP_FILES_URL}thumbs_${item.brand[0].image}`}
                                                    style={{ width: 100, height: 100, objectFit: 'contain' }} />
                                                <span style={{ margin: '10px 0' }}>{item.name}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Reorder>
                    </div>
                </Row>
            </Layout.Content>
        )
    }
}