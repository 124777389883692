import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadUsersData,
    submitAddBrand,
    submitUpdateUser
} from './actions';
import Page from './Page';

class User extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const BrandForm = Form.create({ name: 'users_form' })(User);

function mapStateToProps(state) {
    return {
        usersLoading: state.brand.usersLoading,
        usersData: state.brand.brandsData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadUsersData: (data) => dispatch(loadUsersData(data)),
        onSubmitAddBrand: (data) => dispatch(submitAddBrand(data)),
        onSubmitUpdateUser: (data) => dispatch(submitUpdateUser(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandForm);
