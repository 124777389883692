import Axios from '../../../utils/axios'

export const fetchDashboardData = (data) => {
  return dispatch => {
    Axios.get('/admin/distributors-orders', dispatch, 'GET_DASHBOARD_DATA', data)
  }
}

export const fetchDistributorTotalActive = (data) => {
  return dispatch => {
    Axios.get('/admin/distributors-ordered', dispatch, 'GET_DISTRIBUTOR_TOTAL_ACTIVE', data)
  }
}

