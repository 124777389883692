import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    submitAddSubAccount,
    loadUserRolesData,
    loadDistributorsData,
    submitAddDistributor,
    submitUpdateDistributor,
    loadProductVariantData,
    loadProductsData
} from './actions';
import {
    fetchPermissions
} from '../../actions'
import {
    loadUsersData
} from '../Users/actions'
import Page from './Page';

class Distributor extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const DistributorForm = Form.create({ name: 'distributor_form' })(Distributor);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        usersIsLoading: state.users.userLoading,
        users: state.users.usersData,
        rolesIsLoading: state.roles.userRolesLoading,
        roles: state.roles.userRolesData,
        distributorLoading: state.distributor.distributorLoading,
        distributorsData: state.distributor.distributorsData,
        distributorPricelistLoading: state.distributor.distributorPricelistLoading,
        distributorPricelistsData: state.distributor.distributorPricelistsData,
        distributorProductVariantData: state.distributor.productVariantData,
        distributorProductVariantLoaded: state.distributor.productVariantLoaded,
        productsData: state.distributor.productsData,
        productsLoading: state.distributor.productsLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadUsersData: (data) => dispatch(loadUsersData(data)),
        onLoadUserRolesData: (data) => dispatch(loadUserRolesData(data)),
        onSubmitAddSubAccount: (data) => dispatch(submitAddSubAccount(data)),
        onLoadDistributorsData: (data) => dispatch(loadDistributorsData(data)),
        onSubmitAddDistributor: (data, searchParams) => dispatch(submitAddDistributor(data, searchParams)),
        onSubmitUpdateDistributor: (data, searchParams) => dispatch(submitUpdateDistributor(data, searchParams)),
        onLoadProductVariantData: (data) => dispatch(loadProductVariantData(data)),
        onLoadProductsData: (data) => dispatch(loadProductsData(data)),
        // onFetchProductVariantData: (data) => dispatch(fetchProductVariantData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributorForm);
