import React from 'react'
import { Row, Col, Button, Form, Modal, Layout, Select, Input } from 'antd';
import ImageUploader from '../../../shared/ImageUploader'

const { Content } = Layout;
const Option = Select.Option;

export default function ModalForm(props) {
  const { selectedRow, isLoading } = props;

  return (
    <Modal
      title={`${selectedRow ? "Edit" : 'Add'} Product Variant`}
      visible={props.showFormModal}
      onCancel={props.handleCloseModal}
      centered={true}
      footer={null}
      width={800}
    >
      <Layout>
        <Content className='contact-form-container'>
          <Row type="flex" justify="center" gutter={30}>
            <Form onSubmit={props.handleSubmit} className="login-form">
              <Col span={8} align="center" style={{ height: 200 }}>
                <ImageUploader
                  ref={props.imageUploader}
                  fileFolder={`product-variants`}
                  buttonDesc="Click here to choose image"
                  imageUrl={selectedRow ? selectedRow.image : ""}
                />
              </Col>
              <Col span={16}>
                <Form.Item label="Variant Name">
                  {props.getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input your Variant Name!' }],
                    initialValue: selectedRow ? selectedRow.name : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              <Col span={16}>
                <Form.Item label="Description">
                  {props.getFieldDecorator('description', {
                    initialValue: selectedRow ? selectedRow.description : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Product">
                  {props.getFieldDecorator('product_id', {
                    rules: [{ required: true, message: 'Please input your product!' }],
                    // initialValue: selectedRow && (selectedRow.product.status && !selectedRow.product.is_disabled) ? `${selectedRow.product.name} - ${selectedRow.product.country_made}` : ""
                    initialValue: selectedRow && (`${selectedRow.product.name} - ${selectedRow.product.country_made}`)
                  })(
                    < Select placeholder='Select a Product' showSearch
                    filterOption={(input, option) =>  
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                      || option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onPopupScroll={(event) => {
                      if (!props.isProductLoading && event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight) {
                        if (props.products.current_page !== props.products.last_page) props.loadActiveProducts({ page: props.products.current_page + 1 });
                      }
                    }}
                    >
                      {
                        props.products ? props.products.data.map((data, index) => {
                          if(data.brand.status === 1 && data.status === "Active"){
                              return (
                              // <Option value={`${data.name} - ${data.country_made}`} key={index}>{data.name} - {data.country_made}</Option>
                              <Option value={data.id} key={index}>{data.name} - {data.country_made}</Option>
                              )
                            }
                        }) : ""
                      }
                    </Select>,
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Variant Size">
                  {props.getFieldDecorator('variants["size"]', {
                    initialValue: selectedRow && selectedRow.variants ? selectedRow.variants.size : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Variant Flavor">
                  {props.getFieldDecorator('variants["flavor"]', {
                    initialValue: selectedRow && selectedRow.variants ? selectedRow.variants.flavor : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>



              <Col span={8}>
                <Form.Item label="Selling Price">
                  {props.getFieldDecorator('selling_price', {
                    rules: [{ required: true, message: 'Please input your selling price!' }],
                    initialValue: selectedRow ? selectedRow.selling_price : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>


              <Col span={8}>
                <Form.Item label="Material Code">
                  {props.getFieldDecorator('material_code', {
                    rules: [{ required: true, message: 'Please input your Material Code!' }],
                    initialValue: selectedRow ? selectedRow.material_code : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Packing Scheme">
                  {props.getFieldDecorator('packing_scheme', {
                    rules: [{ required: true, message: 'Please input your packing scheme!' }],
                    initialValue: selectedRow ? selectedRow.packing_scheme : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="CBM">
                  {props.getFieldDecorator('cbm', {
                    rules: [{ required: true, message: 'Please input your cbm!' }],
                    initialValue: selectedRow ? selectedRow.cbm : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Gross Weight">
                  {props.getFieldDecorator('gross_weight', {
                    // rules: [{ required: true, message: 'Please input your gross weight!' }],
                    initialValue: selectedRow ? selectedRow.gross_weight : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Net Weight">
                  {props.getFieldDecorator('net_weight', {
                    // rules: [{ required: true, message: 'Please input your net weight!' }],
                    initialValue: selectedRow ? selectedRow.net_weight : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Unit Of Measurement">
                  {props.getFieldDecorator('uom', {
                    // rules: [{ required: true, message: 'Please input your unit of measurement!' }],
                    initialValue: selectedRow ? selectedRow.uom : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="M.Unit">
                  {props.getFieldDecorator('m_unit', {
                    // rules: [{ required: true, message: 'Please input your m.unit!' }],
                    initialValue: selectedRow ? selectedRow.m_unit : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Dimensions">
                  {props.getFieldDecorator('dimension', {
                    // rules: [{ required: true, message: 'Please input your dimensions!' }],
                    initialValue: selectedRow ? selectedRow.dimension : ""
                  })(
                    <Input />,
                  )}
                </Form.Item>
              </Col>

              {/* <Col span={8}>
                <Form.Item label="Country">
                  {props.getFieldDecorator('country', {
                    rules: [{ required: true, message: 'Please input your Country!' }],
                    initialValue: selectedRow ? selectedRow.country : ""
                  })(
                    < Select placeholder='Select a Country' >
                      <Option value='PH'>Philippines</Option>
                      <Option value='VN'>Vietnam</Option>
                      <Option value='TH'>Thailand</Option>
                      <Option value='MY'>Malaysian</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col> */}

              <Col span={8}>
                <Form.Item label="Status">
                  {props.getFieldDecorator('status', {
                    rules: [{ required: true, message: 'Please input your Status!' }],
                    initialValue: selectedRow ? selectedRow.status.toString() : ""
                  })(
                    < Select placeholder='Select a Status' showSearch>
                      <Option value='Active'>Active</Option>
                      <Option value='Inactive'>Inactive</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span={24} align="right">
                <Form.Item>
                  <Button key="back" onClick={props.handleCloseModal} disabled={isLoading}>
                    Close
                  </Button>
                  &nbsp;
                  <Button type="primary" htmlType="submit" className="login-form-button" disabled={isLoading} loading={isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Row>
        </Content>
      </Layout>
    </Modal>
  )
}
