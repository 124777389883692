import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadFAQCategory,
    updateSortList,
    removedQuestionAndAnswer,
    updateType,
    updateStatus
} from './actions'
import {
    fetchPermissions
} from '../../actions'
import Page from './Page';

class Order extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const OrderForm = Form.create({ name: 'order_form' })(Order);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        faqCategoryData: state.FAQ.faqCategoryData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadFAQCategory: (data) => dispatch(loadFAQCategory(data)),
        onUpdateSortList: (data) => dispatch(updateSortList(data)),
        onRemovedQuestionAndAnswer: (data) => dispatch(removedQuestionAndAnswer(data)),
        onUpdateType: (data) => dispatch(updateType(data)),
        onUpdateStatus: (data) => dispatch(updateStatus(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
