import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import * as _ from 'lodash'
import { getUser } from '../../../utils/token'
import './Header.scss'

function Header({ location, paths }) {
  let selectedKeys = _.chain(paths).filter(path => location.pathname.includes(path.slug)).map('slug').value()
  selectedKeys = !selectedKeys.length ? [_.find(paths, ['default', true]).slug] : selectedKeys

  function onLogout() {
    localStorage.clear()
    window.location.href='/'
  }

  const user = JSON.parse(getUser())

  return (
    <Layout.Header>
      <Link to='/' className='logo' />
      <Menu className='ant-menu-urc right' mode='horizontal' defaultSelectedKeys={selectedKeys} selectedKeys={selectedKeys} style={{ float: 'right', lineHeight: '61px' }}>
        <Menu.Item className='search'><Link to='/'>{user ? user.username ? user.username : user.email : null}</Link></Menu.Item>
        <Menu.Item className='login-signup' onClick={onLogout} style={{ color: "white" }}>Logout</Menu.Item>
      </Menu>
    </Layout.Header>
  )
}

export default Header
