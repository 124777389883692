import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Layout, Spin, Row, Card } from 'antd';
import './index.scss';

import PortfolioBrands from './components/portfolioBrand'
class Homepage extends Component {

  render() {
    return (
      <Layout>
        <Layout.Content style={{ padding: 20 }}>
          <Card>
            <PortfolioBrands {...this.props} />
          </Card>
        </Layout.Content>
      </Layout>
    )
  }
}

const LoginForm = Form.create({ name: 'login_form' })(Homepage);

export default LoginForm;