import React, { Component } from 'react';
import { Layout, Row, Col, Input, Select, Result, Tag, Form, Table } from 'antd';
import ReadMoreAndLess from 'react-read-more-less'
import moment from 'moment'
// Import CSS
import './InquiryDb.scss'
const { Content } = Layout
const { Search } = Input;

class InquiryDb extends Component {
  constructor() {
    super()
    this.state = {
      tableData: []
    }
  }

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadInquiryDb()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.inquiryDbData !== nextProps.inquiryDbData) {
      this.setState({
        tableData: nextProps.inquiryDbData.sort(function (a, b) {
          return (b.id - a.id);
        })
      })
    }
  }
  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    //function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    };

    const { inquiryDbData } = this.props;
    const newArrayObj = inquiryDbData;
    //if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ tableData: newArrayObj });
    }
    let distributorArray = [];
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach((keys) => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem });
        let isFound =
          rowItemKeys &&
          rowItemKeys.toLowerCase().includes(searchTerm.toLowerCase());
        if (isFound) distributorArray.push(rowItem);
      });
    });
    //Removed Duplicate Entry in Array.
    const newArrayElement = distributorArray.filter(function (
      elem,
      index,
      self
    ) {
      return index === self.indexOf(elem);
    });
    this.setState({ tableData: newArrayElement });
  };

  render() {
    const { myPermissions, myPermissionsLoading } = this.props
    const dataSource = this.state.tableData
    const columns = [
      {
        title: 'Created Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (value) => {
          return (
            <div>{moment(value).format('MM/DD/YYYY')}</div>
          )
        }
      },
      {
        title: 'FAQ Content',
        dataIndex: 'content',
        key: 'content',
        width: 450,
        render: (value, data) => {
          return (
            <div>
              <h4>QUESTION: </h4>
              <span>{data.faq_content.question}</span>
              <br /><br />
              <h4>ANSWER: </h4>
              <ReadMoreAndLess
                // ref={this.ReadMore}
                className="read-more-content"
                charLimit={150}
                readMoreText="Read more"
                readLessText="Read less"
              >
                {data.faq_content.answer}
              </ReadMoreAndLess>
            </div>
          )
        }
      },
      {
        title: 'Response',
        dataIndex: 'response',
        key: 'response',
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        render: (value, data) => {
          return (
            <div>
              <h4>Was the information helpful? </h4>
              {
                data.is_yes === 'yes' ? <Tag color="#87d068">YES</Tag> : <Tag color="#f50">NO</Tag>
              }

              <br /><br />
              {
                data.is_yes !== 'yes' && <div>
                  <h4>Message: </h4>
                  <span>{data.message}</span>
                </div>
              }

            </div>
          )
        }
      },
    ];

    if (myPermissionsLoading) {
      // return (
      //   <Layout>
      //     <Skeleton active />
      //   </Layout>
      // )
      return null
    }

    return myPermissions.some(r => r.name === 'admin.faq.view') ? (
      // return (
      <div className='inquiry-db-page'>
        <Layout>
          <Content className='inquiry-db-content-page' style={{ padding: 25 }}>
            <Row>
              <Col xs={24} sm={24} md={12}>
                <span className='inquiry-db-title'>
                  INQUIRY DATABASE (FAQ)
                </span>
              </Col>
            </Row>
            <Row style={{
              background: '#FFFFFF',
              boxShadow: "0px 7px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: 6
            }}>
              <Col>
                <Layout.Content style={{ margin: 24, backgroundColor: 'white' }}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='inquiry-db-search'>
                    <Col md={8}>
                      <Search
                        placeholder="Search"
                        onSearch={(value) =>
                          this.handleSearch(value, [
                            'created_at',
                            'created_at_local',
                            "faq_content.answer",
                            "faq_content.question",
                            "message",
                            "response",
                            "is_yes"
                          ])
                        }
                        style={{ width: '100%' }}
                      />
                    </Col>
                  </Row>
                </Layout.Content>
              </Col>
              <Table dataSource={dataSource} columns={columns} />
            </Row>
          </Content>
        </Layout>
      </div >
    )
      : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

export default Form.create({ name: 'InquiryDB' })(InquiryDb);