import { getName } from 'country-list'

const initialData = {
    inquiryDashboardData: [],
    inquiryDashboardLoading: false,
  }
  export default function dashboard(state = initialData, action) {
    switch (action.type) {
      case 'GET_INQUIRY_DASHBOARD_DATA':
        return {
          ...state,
          inquiryDashboardLoading: true
        }
      case 'GET_INQUIRY_DASHBOARD_DATA_FULFILLED':
        action.payload.map(data => {
          data.country = getName(data.country)
        })
        return {
          ...state,
          inquiryDashboardData: action.payload,
          inquiryDashboardLoading: false
        }
      case 'GET_INQUIRY_DASHBOARD_DATA_REJECTED':
        return {
          ...state,
          inquiryDashboardData: [],
          inquiryDashboardLoading: false
        }
  
      default:
        return {
          ...state
        }
    }
  }
  