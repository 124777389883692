import React, { Component } from 'react';
import { Form, Icon, Button, Layout, Row, Col, Table, Dropdown, Menu, Input, Modal, Skeleton, Result, Switch, Popover, Select } from 'antd';
import ModalForm from './components/ModalForm';
import ModalView from './components/ModalView';
import moment from 'moment'

import './Products.scss'

const { Content } = Layout;
const { Search } = Input;
const ButtonGroup = Button.Group;
const { Option } = Select;

class Product extends Component {
  initialState = {
    showFormModal: false,
    showViewModal: false,
    selectedRow: null,
    modalLoading: false,
    tableData: [],
    filterBy: [
      'products.name'
    ]
  }

    // Inital State for the sorting
    sortState = {
      sortBy: 'products.created_at',
      orderBy: 'asc',
      filters: JSON.stringify({ 'products.status': [1] })
    };
  
    // Initial state for searching
    searchState = {};
  
  brands = [];

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.imageUploader = React.createRef();
  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ modalLoading: true })
    const { onSubmitAddProduct, onSubmitUpdateProduct, brandsData } = this.props
    const { selectedRow, tableData } = this.state;
    const data = {
      page: tableData.current_page
    }

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let params = {
          ...values
        }

        let media = null;
        let imageUrl =
          this.imageUploader.current === null
            ? ""
            : this.imageUploader.current.getImageUrl();
        media =
          this.imageUploader.current === null
            ? null
            : await this.imageUploader.current.handleUploadRequest();
        params.image = media ? media.url : imageUrl;
        params.status = params.status === 'Active' ? 1 : 0

        brandsData.data.map(brand => {
          if (params.brand_id === brand.name) {
            return params.brand_id = brand.id
          }
        })

        if (selectedRow) {
          params.id = selectedRow.id;
          await onSubmitUpdateProduct(params, {...this.sortState, ...data, ...this.searchState})
            .then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Unable to update Product.',
                  content: ''
                })
              } else {
                Modal.success({
                  title: 'Product has been updated successfully',
                  content: ''
                })
              }
              this.setState({ modalLoading: false })
            })
        } else {
          await onSubmitAddProduct(params, {...this.sortState, ...data, ...this.searchState})
            .then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Unable to add Product.',
                  content: ''
                })
              } else {
                Modal.success({
                  title: 'Product has been added successfully',
                  content: ''
                })
              }
              this.setState({ modalLoading: false })
            })
        }

        this.props.form.resetFields();
        this.handleCloseModal();
      }
    });
  };

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleShowEditFormModal = row => {
    this.setState({
      showFormModal: true,
      selectedRow: row
    });
  };

  handleShowViewModal = row => {
    this.setState({
      showViewModal: true,
      selectedRow: row
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      selectedRow: null
    });
  };

  handleChangeStatus = (row, status) => {
    const { tableData } = this.state;

    const params = {
      id: row.id,
      status: status
    };
    const data = {
      page: tableData.current_page
    }

    this.props.onSubmitUpdateProduct(params, {...this.sortState, ...data, ...this.searchState})
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update Product.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Product has been updated successfully',
            content: ''
          })
        }
      })
  }

  handleDisableProduct = (row, is_disabled) => {
    const { tableData } = this.state;

    const params = {
      id: row.id,
      is_disabled: is_disabled
    };
    const data = {
      page: tableData.current_page
    }

    this.props.onSubmitDisableProduct(params, {...this.sortState, ...data, ...this.searchState})
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update Product.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Product has been updated successfully',
            content: ''
          })
        }
      })
  }
  handleFilterBy = (value) => {
    let category = ''

    // value === 'all'
    //   ? category = [
    //     'name',
    //     'country_made',
    //     'brand.name',
    //     'brand.description',
    //     'category',
    //     'description',
    //     'status',
    //     'is_disabled',
    //     'created_at',
    //     'created_at_whole',
    //     'created_at_number'
    //   ]
    //   :

    value === 'product'
        ? category = ['products.name']
        : value === 'brand.name'
          ? category = ['brands.name']
          : value === 'country_made'
            ? category = ['products.country_made']
            : category = [
              'name',
              'country_made',
              'brand.name',
              'brand.description',
              'category',
              'description',
              'status',
              'is_disabled',
              'created_at',
              'created_at_whole',
              'created_at_number'
            ]

    this.setState({ filterBy: category, keyword: "" });
    // console.log("CATEGORY ===========", category)
    // const { orderStatus } = this.props;
    // const newArrayObj = orderStatus.data;

    // // if data not yet loaded condition to avoid error
    // if (!newArrayObj) {
    //   return;
    // } else {
    //   // return original data
    //   console.log(newArrayObj);
    //   return this.setState({ tableData: newArrayObj });
    // }
  };

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadActiveBrandsData()
    this.props.onLoadProductData( this.sortState )
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    //function inside function, this will convert the dot string into actual object
    // const convertStringDotNotation = (desc, obj) => {
    //   var arr = desc.split(".");
    //   while (arr.length && (obj = obj[arr.shift()]));
    //   return obj;
    // }
    // const { productData } = this.props
    // const newArrayObj = productData
    // //if SearchTerm is Empty return original data.
    // if (!searchTerm) {
    //   return this.setState({ tableData: newArrayObj })
    // }
    // let distributorArray = []
    // newArrayObj.forEach((rowItem, i) => {
    //   searchKeys.forEach(keys => {
    //     let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
    //     let isFound = (rowItemKeys) && rowItemKeys.toLowerCase().includes(searchTerm.toLowerCase())
    //     if (isFound) distributorArray.push(rowItem)
    //   })
    // })
    // //Removed Duplicate Entry in Array.
    // const newArrayElement = distributorArray.filter(function (elem, index, self) {
    //   return index === self.indexOf(elem);
    // })
    // this.setState({ tableData: newArrayElement })

    this.searchState = searchTerm.trim() === '' ? {} : {
      searchValue: searchTerm,
      searchField: searchKeys
    };

    this.retrieveVariantProducts({
      page: 1, // reset pagination to 1
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.productData !== nextProps.productData) {
      this.setState({ tableData: nextProps.productData })
    }

    if (this.props.brandsData.data !== nextProps.brandsData.data) {
      this.brands = nextProps.brandsData;
      var data = nextProps.brandsData.data;
      if (this.props.brandsData.data) {
        data = [].concat(this.props.brandsData.data, nextProps.brandsData.data);
      } 
      this.brands.data = data;
    }
  }

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.sortState = {
      sortBy: sorter.columnKey || this.sortState.sortBy,
      orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
      filters: JSON.stringify(filters)
    }

    if (!sorter.order) {
      ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
    }

    this.retrieveVariantProducts({
      page: pagination.current
    });
  }

  retrieveVariantProducts(data) {
    this.props.onLoadProductData({...this.sortState, ...data, ...this.searchState});
  }

  render() {
    const { showFormModal, showViewModal, selectedRow, tableData, modalLoading, filterBy } = this.state
    const {
      myPermissions, myPermissionsLoading,
      brandsData,
      productLoading,
      disabledProductLoading,
      updateProductLoading,
      productAddLoading,
      form: { getFieldDecorator },
      brandLoading
    } = this.props
    const dataSource = tableData;
    const dataProduct = tableData.data;

    if (dataProduct === undefined) return false; 
    
    dataProduct.sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix())
    // console.log(dataSource)
    const columns = [
      {
        title: 'SHOW/HIDE',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 150,
        // sorter: (a, b) => {
        //   const x = a.is_disabled;
        //   const y = b.is_disabled;
        //   if (x > y) { return -1; }
        //   if (x < y) { return 1; }
        //   return 0;
        // },
        render: (id, row) => {
          return (                                                                      // || row.status === 'Inactive' Add this to the disabled, if toggle should be disabled when inactive
            <Switch checked={row.is_disabled === 'Enabled' ? true : false} disabled={(row.brand && row.brand.status === 0) || row.status === 'Inactive' ? true : false} onChange={(checked) => {
              checked
                ? this.handleDisableProduct(row, 0)
                : this.handleDisableProduct(row, 1)
            }} />
          )
        }
      },
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 200,
        render: (image, row) => {
          const img_style = { height: 80, objectFit: 'contain' };
          const img_style_inactive = { height: 80, objectFit: 'contain', filter: 'grayscale(100%)' }
          if (!image) {
            return <div style={{ height: 90 }}></div>;
          }
          return <div style={{ height: 90, margin: 0, padding: 0 }}><img src={process.env.REACT_APP_FILES_URL + image} alt={row.name} width={80} style={(row.brand && row.brand.status === 1) ? img_style : img_style_inactive} /></div>
        }
      },
      {
        title: 'Product',
        dataIndex: 'name',
        key: 'products.name',
        sorter: (a, b) => {
          // const x = a.name.toLowerCase();
          // const y = b.name.toLowerCase();
          // if (x < y) { return -1; }
          // if (x > y) { return 1; }
          // return 0;
        },
      },
      {
        title: 'Brand',
        dataIndex: 'brand.name',
        key: 'brands.name',
        sorter: (a, b) => {
          // const x = a.brand.name.toLowerCase();
          // const y = b.brand.name.toLowerCase();
          // if (x < y) { return -1; }
          // if (x > y) { return 1; }
          // return 0;
        },
      },
      {
        title: 'Country',
        dataIndex: 'country_made',
        key: 'products.country_made',
        sorter: (a, b) => {
          // const x = a.country_made.toLowerCase();
          // const y = b.country_made.toLowerCase();
          // if (x < y) { return -1; }
          // if (x > y) { return 1; }
          // return 0;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'products.status',
        align: 'center',
        width: 200,
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }],
        defaultFilteredValue: [1],
        // onFilter: (value, record) => record.status === value,
        render: (tags, row) => {
          const status = row.status
          // return <span>{status}</span>
          let htmlElement
          if (status === 'Active') htmlElement = <Button className='active'>ACTIVE</Button>
          else if (status === 'Inactive') htmlElement = <Button className='inactive'>INACTIVE</Button>
          return (
            <div>
              <Dropdown
                key={row.id}
                className='status-dropdown'
                disabled={(row.brand && row.brand.status === 0)}
                overlay={
                  <Menu key={row.id} style={{ top: -40, left: -10 }}>
                    <Menu.Item>
                      <Button
                        className='active'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus(row, 1)
                        }}>ACTIVE</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='inactive'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus(row, 0)
                        }}>INACTIVE</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                // visible={this.state.visible}
                onClick={(e) => {
                  this.setState({ visible: true })
                  e.stopPropagation()
                }} >
                {htmlElement}
              </Dropdown>
            </div>
          )
        }
      },
      // {
      //   title: 'Is Disabled',
      //   dataIndex: 'is_disabled',
      //   key: 'is_disabled',
      //   align: 'center',
      //   width: 200,
      //   width: 200,
      //   sorter: (a, b) => {
      //     const x = a.is_disabled.toLowerCase();
      //     const y = b.is_disabled.toLowerCase();
      //     if (x < y) { return -1; }
      //     if (x > y) { return 1; }
      //     return 0;
      //   },
      //   render: (tags, row) => {
      //     const is_disabled = row.is_disabled
      //     return <span>{is_disabled}</span>
      //   }
      // },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: 200,
        render: (id, row) => {
          return (

            <div>
              <Popover content={(<p>View</p>)}>
                <span onClick={() => (row.brand && row.brand.status === 1) && this.handleShowViewModal(row)} style={{ cursor: (row.brand && row.brand.status === 0) ? 'no-drop' : 'pointer', margin: 5 }}>
                  <Icon type="eye" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
              <Popover content={(<p>Edit</p>)}>
                <span onClick={() => (row.brand && row.brand.status === 1) && this.handleShowEditFormModal(row)} style={{ cursor: (row.brand && row.brand.status === 0) ? 'no-drop' : 'pointer', margin: 5 }}>
                  <Icon type="edit" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
            </div>
            // <Dropdown overlay={(
            //   <Menu>
            //     {(row.status === 'Active')
            //       ?
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.products.edit')}
            //         onClick={() => this.handleChangeStatus(row, 0)}>Deactivate</Menu.Item>
            //       :
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.products.edit')}
            //         onClick={() => this.handleChangeStatus(row, 1)}>Activate</Menu.Item>
            //     }
            //     {(row.is_disabled === 'Enabled')
            //       ?
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.products.edit')}
            //         onClick={() => this.handleDisableProduct(row, 1)}>Disable</Menu.Item>
            //       :
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.products.edit')}
            //         onClick={() => this.handleDisableProduct(row, 0)}>Enable</Menu.Item>
            //     }
            //     <Menu.Item
            //       disabled={!myPermissions.some(r => r.name === 'admin.products.edit')}
            //       onClick={() => this.handleShowEditFormModal(row)}>Edit Details</Menu.Item>
            //     <Menu.Item onClick={() => this.handleShowViewModal(row)}>View Details</Menu.Item>
            //   </Menu>
            // )}>
            //   <Button>
            //     Actions <Icon type="down" />
            //   </Button>
            // </Dropdown>
          )
        }
      }

    ];

    if (myPermissionsLoading) {
      // return (
      //   <Layout>
      //     <Skeleton active />
      //   </Layout>
      // )
      return null
    }

    return myPermissions.some(r => r.name === 'admin.products.view') ? (
      <div>
        <Layout>
          <Content style={{ padding: 25 }}>
            <Row>
              <Col span={12} className="page-title">
                Products
              </Col>
              <Col span={12} align="right">
                <ButtonGroup style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                  <Button
                    className='btn-small btn-primary'
                    disabled={!myPermissions.some(r => r.name === 'admin.products.create')}
                    type="primary"
                    onClick={this.handleShowFormModal}
                    style={{ margin: '10px' }} ><Icon type="plus" /> Add Product</Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='products-container'>
                  <Search className='search-container'
                    size="large"
                    placeholder="Search"
                    onSearch={(value) => this.handleSearch(value, filterBy
                      // [
                      //   'name',
                      //   'country_made',
                      //   'brand.name',
                      //   'brand.description',
                      //   'category',
                      //   'description',
                      //   'status',
                      //   'is_disabled',
                      //   'created_at',
                      //   'created_at_whole',
                      //   'created_at_number'
                      // ]
                    )} />
                  <Select style={{ width: 160 }}
                    // suffixIcon={<img src={FilterIcon} />}
                    size="large"
                    placeholder="Filter"
                    defaultValue="product"
                    onChange={(value) => this.handleFilterBy(value)}
                    dropdownClassName="--select"
                    autoFocus={true}
                    showSearch
                  >
                    {/* <Option value="all">All</Option> */}
                    <Option value="product">Product</Option>
                    <Option value="brand.name">Brand</Option>
                    <Option value="country_made">Country</Option>
                    {/* {columns.map((value) => (
                            <Option
                              key={value.key}
                              value={value.key}
                            >
                              {value.key.toLowerCase()}
                            </Option>
                          )).filter(value => value.key !== 'poCreatedAtWhole')} */}
                  </Select>
                  <Table
                    rowKey="id"
                    loading={productLoading || disabledProductLoading || updateProductLoading}
                    dataSource={dataProduct}
                    columns={columns}
                    onChange={this.handleOnChangeTableListener}
                    pagination={{ total: dataSource.total, current: dataSource.current_page, pageSize: dataSource.per_page || 10 }} />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
        {showFormModal &&
          <ModalForm
            showFormModal={showFormModal}
            handleCloseModal={this.handleCloseModal}
            handleSubmit={this.handleSubmit}
            imageUploader={this.imageUploader}
            selectedRow={selectedRow}
            getFieldDecorator={getFieldDecorator}
            isLoading={productLoading || disabledProductLoading || updateProductLoading || productAddLoading || modalLoading}
            brands={this.brands}
            loadActiveBrands={this.props.onLoadActiveBrandsData}
            isBrandLoading={brandLoading}
          />
        }
        <ModalView
          showFormModal={showViewModal}
          handleCloseModal={this.handleCloseModal}
          selectedRow={selectedRow}
        />
      </div >
    ) : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

const ProductForm = Form.create({ name: 'product_form' })(Product);

export default ProductForm;
