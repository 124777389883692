import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadAuditTrail
} from './actions'
import {
    fetchPermissions
} from '../../actions'
import Page from './Page';

class Audit extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const AuditForm = Form.create({ name: 'auditTrailForm' })(Audit);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        auditTrailData: state.auditTrail.auditTrailData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadAuditTrail: (data) => dispatch(loadAuditTrail(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditForm);
