const initialData = {
  adminManagementLoading: false,
  adminManagementData: []
}

export default function brand(state = initialData, action) {
  switch (action.type) {
    case 'GET_ADMIN_MANAGEMENT':
      return {
        ...state,
        adminManagementLoading: true
      }
    case 'GET_ADMIN_MANAGEMENT_FULFILLED':
      action.payload.data.map(data => {
        data.status = data.status !== 0 ? 'Active' : 'Inactive'
        data.is_admin = data.is_admin === 0 ? 'User' : data.is_admin === 1 ? 'Admin' : 'Super Admin'
      })
      return {
        ...state,
        adminManagementData: action.payload,
        adminManagementLoading: false
      }
    case 'GET_ADMIN_MANAGEMENT_REJECTED':
      return {
        ...state,
        adminManagementData: null,
        adminManagementLoading: false
      }

    default:
      return {
        ...state
      }
  }
}
